import { Timezone } from '../charting_library/charting_library.min'
import { ICandle, ITrade } from './types'
import { roundNumberWithBase } from './format'
import BigNumber from 'bignumber.js'
import { AreaChart, BarsChart, BaseLine, CandleChart, HeikinAshi, HollowCandle, LineChart } from '../assets/icon'

export const DEFAULT_TRADING_VIEW_INTERVAL = '60'

export const addTradeToLastCandle = (
  trade: ITrade,
  lastCandle: ICandle,
  intervalInMilliseconds: number,
  chartRealtimeCallback: (candle: ICandle) => void
): ICandle => {
  const lastCandleEndTime = lastCandle.time + intervalInMilliseconds
  const tradePrice = Number(trade.price)
  const tradeTime = new Date(trade.timestamp || 0).getTime()
  const volume = Number(trade.quantity) * Number(trade.price)

  if (tradeTime >= lastCandleEndTime) {
    const newCandle: ICandle = {
      open: Number(lastCandle.close),
      close: tradePrice,
      high: Math.max(tradePrice, lastCandle.close),
      low: Math.min(tradePrice, lastCandle.close),
      time: roundNumberWithBase(tradeTime, intervalInMilliseconds),
      volume: volume
    }
    chartRealtimeCallback(newCandle)
    return newCandle
  } else {
    lastCandle.low = Math.min(tradePrice, lastCandle.low)
    lastCandle.high = Math.max(tradePrice, lastCandle.high)
    lastCandle.close = tradePrice
    lastCandle.volume += volume
    chartRealtimeCallback(lastCandle)
    return lastCandle
  }
}

export const createEmptyCandleIfNeeded = (
  lastCandle: ICandle,
  intervalInMilliseconds: number,
  chartRealtimeCallback: (candle: ICandle) => void
): ICandle => {
  const lastCandleEndTime = lastCandle.time + intervalInMilliseconds
  const tradePrice = lastCandle.close
  const tradeTime = roundNumberWithBase(Date.now(), intervalInMilliseconds)
  if (tradeTime >= lastCandleEndTime) {
    const newCandle: ICandle = {
      open: tradePrice,
      close: tradePrice,
      high: tradePrice,
      low: tradePrice,
      time: tradeTime,
      volume: 0
    }
    if (chartRealtimeCallback) {
      chartRealtimeCallback(newCandle)
    }
    return newCandle
  }
  return lastCandle
}

export function getClientTimezone(): Timezone {
  const timezones: { [key: string]: number } = {
    'America/New_York': -5,
    'America/Los_Angeles': -8,
    'America/Chicago': -6,
    'America/Phoenix': -7,
    'America/Toronto': -5,
    'America/Vancouver': -8,
    'America/Argentina/Buenos_Aires': -3,
    'America/El_Salvador': -6,
    'America/Sao_Paulo': -3,
    'America/Bogota': -5,
    'America/Caracas': -4,
    'Europe/Moscow': 3,
    'Europe/Athens': 2,
    'Europe/Belgrade': 1,
    'Europe/Berlin': 1,
    'Europe/London': 0,
    'Europe/Luxembourg': 1,
    'Europe/Madrid': 1,
    'Europe/Paris': 1,
    'Europe/Rome': 1,
    'Europe/Warsaw': 1,
    'Europe/Istanbul': 3,
    'Europe/Zurich': 1,
    'Australia/Sydney': 10,
    'Australia/Brisbane': 10,
    'Australia/Adelaide': 9.5,
    'Australia/ACT': 10,
    'Asia/Almaty': 6,
    'Asia/Ashkhabad': 5,
    'Asia/Tokyo': 9,
    'Asia/Taipei': 8,
    'Asia/Singapore': 8,
    'Asia/Shanghai': 8,
    'Asia/Seoul': 9,
    'Asia/Tehran': 3.5,
    'Asia/Dubai': 4,
    'Asia/Kolkata': 5.5,
    'Asia/Hong_Kong': 8,
    'Asia/Bangkok': 7,
    'Asia/Chongqing': 8,
    'Asia/Jerusalem': 2,
    'Asia/Kuwait': 3,
    'Asia/Muscat': 4,
    'Asia/Qatar': 3,
    'Asia/Riyadh': 3,
    'Pacific/Auckland': 12,
    'Pacific/Chatham': 12.75,
    'Pacific/Fakaofo': 13,
    'Pacific/Honolulu': -10,
    'America/Mexico_City': -6,
    'Africa/Cairo': 2,
    'Africa/Johannesburg': 2,
    'Asia/Kathmandu': 5.75,
    'US/Mountain': -7
  }

  const timezone = (new Date().getTimezoneOffset() * -1) / 60
  for (const key in timezones) {
    if (timezones[key] == timezone) {
      return key as Timezone
    }
  }
  return 'Etc/UTC'
}

export const getResolutionInMinutes = (interval: string): number => {
  const stringIntervals: { [key: string]: number } = {
    '1D': 24 * 60,
    D: 3 * 24 * 60, // it should be '3D', but there is a bug of TradingView, it call get bars with resolution D
    '3D': 3 * 24 * 60,
    '1W': 7 * 24 * 60,
    '1M': 30 * 24 * 60,
    '1S': new BigNumber(1).dividedBy(60).toNumber()
  }
  if (stringIntervals[interval]) {
    return stringIntervals[interval]
  } else {
    return Number(interval)
  }
}

export function getResolutionString(interval: number): string {
  const days = interval / 24 / 60
  if (days >= 30) return '1M'
  if (days >= 7) return '1W'
  if (days >= 3) return '3D'
  if (days === 1) return '1D'
  return interval.toString()
}

export const getMaxYAxis = (max: number | string) => {
  const convertNumber = Number(max)
  if (!convertNumber)
    return {
      max: 10,
      decimalPlace: 0
    }

  let result = 0
  let exp = 0
  let decimalPlace = 0
  const log10: number = Math.log10(convertNumber)

  if (convertNumber >= 1) {
    exp = Math.ceil(log10)
    result = Math.pow(10, exp)
    decimalPlace = 1
  } else {
    exp = Math.abs(Math.floor(log10)) - 1
    result = Number(Math.pow(10, -exp).toFixed(exp))
    decimalPlace = Math.abs(Math.floor(log10)) + 2
  }
  // result to find the nearest power of 10 greater than 1 number

  // Compare to find nearest
  if (convertNumber < result / 4) {
    result = result / 4
  } else if (convertNumber < result / 2) {
    result = result / 2
  } else {
    result = result
  }

  return {
    max: result,
    decimalPlace
  }
}

export enum THEME_MODE {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum SYMBOL_TYPE {
  stock = 'stock',
  bitcoin = 'bitcoin'
}

export const intervarMasterArr = [
  {
    row: [
      { key: 'Time', value: '1', valueCompare: 1, status: false },
      { key: '1s', value: '1S', valueCompare: 0.1, status: false },
      { key: '1m', value: '1', status: false, valueCompare: 1 },
      { key: '3m', value: '3', status: false, valueCompare: 3 }
    ]
  },
  {
    row: [
      { key: '5m', value: '5', status: false, valueCompare: 5 },

      { key: '15m', value: '15', status: false, valueCompare: 15 },
      {
        key: '30m',
        value: '30',
        status: false,
        valueCompare: 30
      },
      {
        key: '1h',
        value: '60',
        status: false,
        valueCompare: 60
      }
    ]
  },
  {
    row: [
      { key: '4h', value: '240', status: false, valueCompare: 240 },
      { key: '6h', value: '360', status: false, valueCompare: 360 },
      { key: '8h', value: '480', status: false, valueCompare: 480 },
      { key: '12h', value: '720', status: false, valueCompare: 720 }
    ]
  },
  {
    row: [
      {
        key: '2h',
        value: '2h',
        status: false,
        valueCompare: 120
      },
      { key: '1D', value: '1D', status: false, valueCompare: 1440 },
      { key: '3D', value: '3D', status: false, valueCompare: 4320 },
      { key: '1W', value: '1W', status: false, valueCompare: 10080 },
      { key: '1M', value: '1M', status: false, valueCompare: 43200 }
    ]
  }
]

export const CHART_TYPE: any[] = [
  {
    type_number: 0,
    type_name: 'Bars',
    src_type: BarsChart
  },
  {
    type_number: 1,
    type_name: 'Candles',
    src_type: CandleChart
  },
  {
    type_number: 2,
    type_name: 'Line',
    src_type: LineChart
  },
  {
    type_number: 3,
    type_name: 'Area',
    src_type: AreaChart
  },
  {
    type_number: 8,
    type_name: 'Heiken ashi',
    src_type: HeikinAshi
  },
  {
    type_number: 9,
    type_name: 'Hollow candles',
    src_type: HollowCandle
  },
  {
    type_number: 10,
    type_name: 'Baseline',
    src_type: BaseLine
  }
]

export const dataMock = [
  {
    open: 42660.37,
    close: 42695.1,
    low: 42551.96,
    high: 42739.86,
    volume: 0.205,
    time: 1703588400000
  },
  {
    open: 42695.1,
    close: 42748.88,
    low: 42611.46,
    high: 42794.14,
    volume: 0.209,
    time: 1703592000000
  },
  {
    open: 42748.88,
    close: 42699,
    low: 42633.38,
    high: 42782.24,
    volume: 0.209,
    time: 1703595600000
  },
  {
    open: 42699,
    close: 42617.85,
    low: 42556.3,
    high: 42834.74,
    volume: 0.211,
    time: 1703599200000
  },
  {
    open: 42617.85,
    close: 42362.41,
    low: 42324.04,
    high: 42752.2,
    volume: 0.211,
    time: 1703602800000
  },
  {
    open: 42362.41,
    close: 42338.52,
    low: 42157.62,
    high: 42418.08,
    volume: 0.213,
    time: 1703606400000
  },
  {
    open: 42338.52,
    close: 41996.19,
    low: 41658.7,
    high: 42362.25,
    volume: 0.213,
    time: 1703610000000
  },
  {
    open: 41996.19,
    close: 42167.81,
    low: 41836.19,
    high: 42182.92,
    volume: 0.212,
    time: 1703613600000
  },
  {
    open: 42167.81,
    close: 42103.4,
    low: 42076.44,
    high: 42310.85,
    volume: 0.218,
    time: 1703617200000
  },
  {
    open: 42103.4,
    close: 42160.26,
    low: 42048.99,
    high: 42245.42,
    volume: 0.208,
    time: 1703620800000
  },
  {
    open: 42160.26,
    close: 42356.72,
    low: 42119.53,
    high: 42356.72,
    volume: 0.217,
    time: 1703624400000
  },
  {
    open: 42356.72,
    close: 42461.22,
    low: 42320.77,
    high: 42596.22,
    volume: 0.212,
    time: 1703628000000
  },
  {
    open: 42461.22,
    close: 42492.43,
    low: 42401.83,
    high: 42539.29,
    volume: 0.211,
    time: 1703631600000
  },
  {
    open: 42492.43,
    close: 42349.32,
    low: 42349.32,
    high: 42557.4,
    volume: 0.211,
    time: 1703635200000
  },
  {
    open: 42349.32,
    close: 42439.58,
    low: 42320.77,
    high: 42503.76,
    volume: 0.20600000000000002,
    time: 1703638800000
  },
  {
    open: 42439.58,
    close: 42210.69,
    low: 42178.83,
    high: 42490.17,
    volume: 0.197,
    time: 1703642400000
  },
  {
    open: 42210.69,
    close: 42243.3,
    low: 42117.13,
    high: 42339.39,
    volume: 0.199,
    time: 1703646000000
  },
  {
    open: 42243.3,
    close: 42288.96,
    low: 42183.46,
    high: 42353.63,
    volume: 0.197,
    time: 1703649600000
  },
  {
    open: 42288.96,
    close: 42469.27,
    low: 42274.36,
    high: 42469.63,
    volume: 0.2,
    time: 1703653200000
  },
  {
    open: 42469.27,
    close: 42442.58,
    low: 42403.14,
    high: 42542.74,
    volume: 0.206,
    time: 1703656800000
  },
  {
    open: 42442.58,
    close: 42444.82,
    low: 42377.62,
    high: 42504.51,
    volume: 0.2,
    time: 1703660400000
  },
  {
    open: 42444.82,
    close: 42697.78,
    low: 42389.53,
    high: 42765.65,
    volume: 0.20299999999999999,
    time: 1703664000000
  },
  {
    open: 42697.78,
    close: 42752.68,
    low: 42618.8,
    high: 42752.68,
    volume: 0.2,
    time: 1703667600000
  },
  {
    open: 42752.68,
    close: 43202.98,
    low: 42689.75,
    high: 43222.74,
    volume: 0.20700000000000002,
    time: 1703671200000
  },
  {
    open: 43202.98,
    close: 42999.92,
    low: 42901.8,
    high: 43215.34,
    volume: 0.216,
    time: 1703674800000
  },
  {
    open: 42999.92,
    close: 42923.64,
    low: 42781.99,
    high: 43062.35,
    volume: 0.208,
    time: 1703678400000
  },
  {
    open: 42923.64,
    close: 42898.44,
    low: 42718.88,
    high: 42981.6,
    volume: 0.214,
    time: 1703682000000
  },
  {
    open: 42898.44,
    close: 42906.85,
    low: 42837.97,
    high: 43143.36,
    volume: 0.206,
    time: 1703685600000
  },
  {
    open: 42906.85,
    close: 42978.8,
    low: 42803.65,
    high: 43086.73,
    volume: 0.213,
    time: 1703689200000
  },
  {
    open: 42978.8,
    close: 43090.4,
    low: 42871.7,
    high: 43189.84,
    volume: 0.214,
    time: 1703692800000
  },
  {
    open: 43090.4,
    close: 43004.04,
    low: 42874.13,
    high: 43130.87,
    volume: 0.205,
    time: 1703696400000
  },
  {
    open: 43004.04,
    close: 43200.81,
    low: 42912.77,
    high: 43229.48,
    volume: 0.22,
    time: 1703700000000
  },
  {
    open: 43200.81,
    close: 43163,
    low: 42995.87,
    high: 43211.8,
    volume: 0.213,
    time: 1703703600000
  },
  {
    open: 43163,
    close: 43450.44,
    low: 43101.66,
    high: 43550.07,
    volume: 0.21100000000000002,
    time: 1703707200000
  },
  {
    open: 43450.44,
    close: 43353.85,
    low: 43250.21,
    high: 43473.93,
    volume: 0.222,
    time: 1703710800000
  },
  {
    open: 43353.85,
    close: 43327.76,
    low: 43278.82,
    high: 43692.17,
    volume: 0.216,
    time: 1703714400000
  },
  {
    open: 43327.76,
    close: 43408.98,
    low: 43316.17,
    high: 43477,
    volume: 0.216,
    time: 1703718000000
  },
  {
    open: 43408.98,
    close: 43534.55,
    low: 43377.5,
    high: 43784.3,
    volume: 0.208,
    time: 1703721600000
  },
  {
    open: 43534.55,
    close: 43437.49,
    low: 43437.49,
    high: 43719.02,
    volume: 0.205,
    time: 1703725200000
  },
  {
    open: 43437.49,
    close: 43375.38,
    low: 43298.28,
    high: 43477.71,
    volume: 0.199,
    time: 1703728800000
  },
  {
    open: 43375.38,
    close: 43422.23,
    low: 43298.81,
    high: 43423.24,
    volume: 0.197,
    time: 1703732400000
  },
  {
    open: 43422.23,
    close: 43330.97,
    low: 43302.79,
    high: 43626.71,
    volume: 0.20400000000000001,
    time: 1703736000000
  },
  {
    open: 43330.97,
    close: 43202.05,
    low: 43130.19,
    high: 43378.79,
    volume: 0.206,
    time: 1703739600000
  },
  {
    open: 43202.05,
    close: 43049.97,
    low: 43004.68,
    high: 43207.35,
    volume: 0.201,
    time: 1703743200000
  },
  {
    open: 43049.97,
    close: 43055.25,
    low: 42813.44,
    high: 43071.59,
    volume: 0.205,
    time: 1703746800000
  },
  {
    open: 43055.25,
    close: 42924.71,
    low: 42915.9,
    high: 43083.53,
    volume: 0.195,
    time: 1703750400000
  },
  {
    open: 42924.71,
    close: 43043.88,
    low: 42872.95,
    high: 43107.11,
    volume: 0.197,
    time: 1703754000000
  },
  {
    open: 43043.88,
    close: 43106.47,
    low: 42999.02,
    high: 43197.94,
    volume: 0.20600000000000002,
    time: 1703757600000
  },
  {
    open: 43106.47,
    close: 43078.6,
    low: 42999.03,
    high: 43154.16,
    volume: 0.20600000000000002,
    time: 1703761200000
  },
  {
    open: 43078.6,
    close: 42771.25,
    low: 42771.25,
    high: 43141.43,
    volume: 0.211,
    time: 1703764800000
  },
  {
    open: 42771.25,
    close: 42801.51,
    low: 42605.67,
    high: 42918.52,
    volume: 0.20700000000000002,
    time: 1703768400000
  },
  {
    open: 42801.51,
    close: 42647.04,
    low: 42450.83,
    high: 42826.4,
    volume: 0.211,
    time: 1703772000000
  },
  {
    open: 42647.04,
    close: 42436.59,
    low: 42390.93,
    high: 42720.63,
    volume: 0.213,
    time: 1703775600000
  },
  {
    open: 42436.59,
    close: 42453.95,
    low: 42291.13,
    high: 42575.68,
    volume: 0.20900000000000002,
    time: 1703779200000
  },
  {
    open: 42453.95,
    close: 42529.87,
    low: 42447.44,
    high: 42638.61,
    volume: 0.20800000000000002,
    time: 1703782800000
  },
  {
    open: 42529.87,
    close: 42463.14,
    low: 42383.68,
    high: 42643.22,
    volume: 0.212,
    time: 1703786400000
  },
  {
    open: 42463.14,
    close: 42625.91,
    low: 42454.28,
    high: 42676.89,
    volume: 0.21,
    time: 1703790000000
  },
  {
    open: 42625.91,
    close: 42492.15,
    low: 42372.87,
    high: 42625.91,
    volume: 0.214,
    time: 1703793600000
  },
  {
    open: 42492.15,
    close: 42433.34,
    low: 42339.18,
    high: 42631.21,
    volume: 0.208,
    time: 1703797200000
  },
  {
    open: 42433.34,
    close: 42615.65,
    low: 42371.12,
    high: 42645.77,
    volume: 0.209,
    time: 1703800800000
  },
  {
    open: 42615.65,
    close: 42554.67,
    low: 42521.65,
    high: 42762.02,
    volume: 0.213,
    time: 1703804400000
  },
  {
    open: 42554.67,
    close: 42391.37,
    low: 42356.3,
    high: 42690.59,
    volume: 0.205,
    time: 1703808000000
  },
  {
    open: 42391.37,
    close: 42351.36,
    low: 42092.81,
    high: 42557.11,
    volume: 0.207,
    time: 1703811600000
  },
  {
    open: 42351.36,
    close: 42564.37,
    low: 42255.56,
    high: 42614.48,
    volume: 0.2,
    time: 1703815200000
  },
  {
    open: 42564.37,
    close: 42723.34,
    low: 42445.09,
    high: 42780.73,
    volume: 0.198,
    time: 1703818800000
  },
  {
    open: 42723.34,
    close: 42663.48,
    low: 42585.3,
    high: 42758.14,
    volume: 0.202,
    time: 1703822400000
  },
  {
    open: 42663.48,
    close: 42679.01,
    low: 42487.83,
    high: 42718.89,
    volume: 0.21000000000000002,
    time: 1703826000000
  },
  {
    open: 42679.01,
    close: 42516.18,
    low: 42512.69,
    high: 42698.5,
    volume: 0.21,
    time: 1703829600000
  },
  {
    open: 42516.18,
    close: 42263.47,
    low: 42220.35,
    high: 42583.43,
    volume: 0.206,
    time: 1703833200000
  },
  {
    open: 42263.47,
    close: 42467.76,
    low: 42263.47,
    high: 42522.79,
    volume: 0.20600000000000002,
    time: 1703836800000
  },
  {
    open: 42467.76,
    close: 42668.88,
    low: 42405.57,
    high: 42685.33,
    volume: 0.206,
    time: 1703840400000
  },
  {
    open: 42668.88,
    close: 42705.45,
    low: 42600.7,
    high: 42796.34,
    volume: 0.211,
    time: 1703844000000
  },
  {
    open: 42705.45,
    close: 42872.77,
    low: 42661.47,
    high: 42915.16,
    volume: 0.213,
    time: 1703847600000
  },
  {
    open: 42872.77,
    close: 42812.21,
    low: 42744.85,
    high: 42937.11,
    volume: 0.208,
    time: 1703851200000
  },
  {
    open: 42812.21,
    close: 42824.01,
    low: 42685.87,
    high: 42900.49,
    volume: 0.216,
    time: 1703854800000
  },
  {
    open: 42824.01,
    close: 42999.32,
    low: 42758.62,
    high: 43099.81,
    volume: 0.205,
    time: 1703858400000
  },
  {
    open: 42999.32,
    close: 42266.05,
    low: 42140.65,
    high: 43044.54,
    volume: 0.216,
    time: 1703862000000
  },
  {
    open: 42266.05,
    close: 41962.91,
    low: 41942.45,
    high: 42344.61,
    volume: 0.214,
    time: 1703865600000
  },
  {
    open: 41962.91,
    close: 41815.89,
    low: 41672.19,
    high: 42050.6,
    volume: 0.215,
    time: 1703869200000
  },
  {
    open: 41815.89,
    close: 42042.46,
    low: 41779.27,
    high: 42090.42,
    volume: 0.212,
    time: 1703872800000
  },
  {
    open: 42042.46,
    close: 42122.19,
    low: 41953.42,
    high: 42168.54,
    volume: 0.212,
    time: 1703876400000
  },
  {
    open: 42122.19,
    close: 42028.6,
    low: 41929.39,
    high: 42124.6,
    volume: 0.215,
    time: 1703880000000
  },
  {
    open: 42028.6,
    close: 41935.54,
    low: 41884.02,
    high: 42226.95,
    volume: 0.211,
    time: 1703883600000
  },
  {
    open: 41935.54,
    close: 41742.27,
    low: 41355.46,
    high: 41958.51,
    volume: 0.214,
    time: 1703887200000
  },
  {
    open: 41742.27,
    close: 42051.45,
    low: 41742.27,
    high: 42101.32,
    volume: 0.206,
    time: 1703890800000
  },
  {
    open: 42051.45,
    close: 42091.85,
    low: 42003.44,
    high: 42204.64,
    volume: 0.209,
    time: 1703894400000
  },
  {
    open: 42091.85,
    close: 42166.64,
    low: 42060.97,
    high: 42217.33,
    volume: 0.20800000000000002,
    time: 1703898000000
  },
  {
    open: 42166.64,
    close: 41928.22,
    low: 41830.79,
    high: 42204.53,
    volume: 0.208,
    time: 1703901600000
  },
  {
    open: 41928.22,
    close: 41980.45,
    low: 41804.53,
    high: 42031.59,
    volume: 0.20900000000000002,
    time: 1703905200000
  },
  {
    open: 41980.45,
    close: 41989.03,
    low: 41953.37,
    high: 42066.6,
    volume: 0.212,
    time: 1703908800000
  },
  {
    open: 41989.03,
    close: 42092.83,
    low: 41968.07,
    high: 42141.94,
    volume: 0.20299999999999999,
    time: 1703912400000
  },
  {
    open: 42092.83,
    close: 42035.93,
    low: 42018.4,
    high: 42162.46,
    volume: 0.214,
    time: 1703916000000
  },
  {
    open: 42035.93,
    close: 41936.24,
    low: 41931.82,
    high: 42143.92,
    volume: 0.20900000000000002,
    time: 1703919600000
  },
  {
    open: 41936.24,
    close: 41563.92,
    low: 41563.92,
    high: 41996.54,
    volume: 0.211,
    time: 1703923200000
  },
  {
    open: 41563.92,
    close: 41709.92,
    low: 41535.77,
    high: 41725.06,
    volume: 0.214,
    time: 1703926800000
  },
  {
    open: 41709.92,
    close: 41791.97,
    low: 41665.42,
    high: 41830.05,
    volume: 0.22,
    time: 1703930400000
  },
  {
    open: 41791.97,
    close: 41912.52,
    low: 41791.97,
    high: 41959.02,
    volume: 0.21799999999999997,
    time: 1703934000000
  },
  {
    open: 41912.52,
    close: 41985.66,
    low: 41863.44,
    high: 42118.59,
    volume: 0.209,
    time: 1703937600000
  },
  {
    open: 41985.66,
    close: 42045.79,
    low: 41882.4,
    high: 42049.57,
    volume: 0.211,
    time: 1703941200000
  },
  {
    open: 42045.79,
    close: 42232.93,
    low: 42026.05,
    high: 42259.67,
    volume: 0.217,
    time: 1703944800000
  },
  {
    open: 42232.93,
    close: 42361.17,
    low: 42175.14,
    high: 42443.86,
    volume: 0.212,
    time: 1703948400000
  },
  {
    open: 42361.17,
    close: 42424.03,
    low: 42361.17,
    high: 42611.39,
    volume: 0.218,
    time: 1703952000000
  },
  {
    open: 42424.03,
    close: 42510.19,
    low: 42260.33,
    high: 42610.15,
    volume: 0.213,
    time: 1703955600000
  },
  {
    open: 42510.19,
    close: 42423.9,
    low: 42326.53,
    high: 42517.39,
    volume: 0.209,
    time: 1703959200000
  },
  {
    open: 42423.9,
    close: 42349.95,
    low: 42324.84,
    high: 42468.71,
    volume: 0.21400000000000002,
    time: 1703962800000
  },
  {
    open: 42349.95,
    close: 42289.61,
    low: 42194.74,
    high: 42413.28,
    volume: 0.211,
    time: 1703966400000
  },
  {
    open: 42289.61,
    close: 42308.02,
    low: 42224.01,
    high: 42330.76,
    volume: 0.211,
    time: 1703970000000
  },
  {
    open: 42308.02,
    close: 42140.15,
    low: 42079.34,
    high: 42321.44,
    volume: 0.209,
    time: 1703973600000
  },
  {
    open: 42140.15,
    close: 42123.08,
    low: 42102.09,
    high: 42300.62,
    volume: 0.216,
    time: 1703977200000
  },
  {
    open: 42123.08,
    close: 42343.72,
    low: 42123.08,
    high: 42416.87,
    volume: 0.216,
    time: 1703980800000
  },
  {
    open: 42343.72,
    close: 42152.7,
    low: 42148.81,
    high: 42429.65,
    volume: 0.208,
    time: 1703984400000
  },
  {
    open: 42152.7,
    close: 42003.44,
    low: 41973.26,
    high: 42251.83,
    volume: 0.212,
    time: 1703988000000
  },
  {
    open: 42003.44,
    close: 42004.94,
    low: 41996.2,
    high: 42016.8,
    volume: 0.207,
    time: 1703991600000
  },
  {
    open: 42004.94,
    close: 42011.14,
    low: 41996.32,
    high: 42016.7,
    volume: 0.199,
    time: 1703995200000
  },
  {
    open: 42011.14,
    close: 42004.76,
    low: 41996.21,
    high: 42016.98,
    volume: 0.216,
    time: 1703998800000
  },
  {
    open: 42004.76,
    close: 42005.08,
    low: 41996.13,
    high: 42016.9,
    volume: 0.214,
    time: 1704002400000
  },
  {
    open: 42005.08,
    close: 42010.44,
    low: 41996.2,
    high: 42017,
    volume: 0.217,
    time: 1704006000000
  },
  {
    open: 42010.44,
    close: 42008.06,
    low: 41996.04,
    high: 42016.73,
    volume: 0.21,
    time: 1704009600000
  },
  {
    open: 42008.06,
    close: 42006.02,
    low: 41996.11,
    high: 42016.73,
    volume: 0.213,
    time: 1704013200000
  },
  {
    open: 42006.02,
    close: 42007.84,
    low: 41996.12,
    high: 42016.98,
    volume: 0.21000000000000002,
    time: 1704016800000
  },
  {
    open: 42007.84,
    close: 42001.48,
    low: 41996.17,
    high: 42017.03,
    volume: 0.204,
    time: 1704020400000
  },
  {
    open: 42001.48,
    close: 41996.21,
    low: 41996.12,
    high: 42017,
    volume: 0.217,
    time: 1704024000000
  },
  {
    open: 41996.21,
    close: 42009.19,
    low: 41996.11,
    high: 42016.98,
    volume: 0.217,
    time: 1704027600000
  },
  {
    open: 42009.19,
    close: 42001.88,
    low: 41996.04,
    high: 42016.95,
    volume: 0.214,
    time: 1704031200000
  },
  {
    open: 42001.88,
    close: 42005.51,
    low: 41996.05,
    high: 42016.87,
    volume: 0.213,
    time: 1704034800000
  },
  {
    open: 42005.51,
    close: 42000.42,
    low: 41996.24,
    high: 42016.68,
    volume: 0.205,
    time: 1704038400000
  },
  {
    open: 42000.42,
    close: 41996.53,
    low: 41996.07,
    high: 42016.85,
    volume: 0.21400000000000002,
    time: 1704042000000
  },
  {
    open: 41996.53,
    close: 41997.04,
    low: 41996.25,
    high: 42016.99,
    volume: 0.207,
    time: 1704045600000
  },
  {
    open: 41997.04,
    close: 42014.79,
    low: 41996.09,
    high: 42016.98,
    volume: 0.212,
    time: 1704049200000
  },
  {
    open: 42014.79,
    close: 42005.06,
    low: 41996.18,
    high: 42017.02,
    volume: 0.217,
    time: 1704052800000
  },
  {
    open: 42005.06,
    close: 42003.67,
    low: 41996.14,
    high: 42016.9,
    volume: 0.212,
    time: 1704056400000
  },
  {
    open: 42003.67,
    close: 42012.17,
    low: 41996.05,
    high: 42016.51,
    volume: 0.216,
    time: 1704060000000
  },
  {
    open: 42012.17,
    close: 42010.35,
    low: 41996.21,
    high: 42016.99,
    volume: 0.2,
    time: 1704063600000
  },
  {
    open: 42010.35,
    close: 41997.87,
    low: 41996.18,
    high: 42016.84,
    volume: 0.218,
    time: 1704067200000
  },
  {
    open: 41997.87,
    close: 42015.24,
    low: 41996.07,
    high: 42016.84,
    volume: 0.20700000000000002,
    time: 1704070800000
  },
  {
    open: 42015.24,
    close: 42011.45,
    low: 41996.08,
    high: 42016.82,
    volume: 0.20900000000000002,
    time: 1704074400000
  },
  {
    open: 42011.45,
    close: 41996.46,
    low: 41996.06,
    high: 42016.92,
    volume: 0.20299999999999999,
    time: 1704078000000
  },
  {
    open: 41996.46,
    close: 42012.57,
    low: 41996.22,
    high: 42017.01,
    volume: 0.20900000000000002,
    time: 1704081600000
  },
  {
    open: 42012.57,
    close: 42013.11,
    low: 41996.06,
    high: 42017,
    volume: 0.221,
    time: 1704085200000
  },
  {
    open: 42013.11,
    close: 42003.03,
    low: 41996.15,
    high: 42016.93,
    volume: 0.213,
    time: 1704088800000
  },
  {
    open: 42003.03,
    close: 42005.45,
    low: 41996.11,
    high: 42016.99,
    volume: 0.207,
    time: 1704092400000
  },
  {
    open: 42005.45,
    close: 42005.14,
    low: 41996.18,
    high: 42017.01,
    volume: 0.216,
    time: 1704096000000
  },
  {
    open: 42005.14,
    close: 42002.23,
    low: 41996.03,
    high: 42016.88,
    volume: 0.207,
    time: 1704099600000
  },
  {
    open: 42002.23,
    close: 42008.9,
    low: 41996.07,
    high: 42016.94,
    volume: 0.22399999999999998,
    time: 1704103200000
  },
  {
    open: 42008.9,
    close: 42008.11,
    low: 41996.31,
    high: 42016.95,
    volume: 0.206,
    time: 1704106800000
  },
  {
    open: 42008.11,
    close: 42003.36,
    low: 41996.25,
    high: 42016.92,
    volume: 0.203,
    time: 1704110400000
  },
  {
    open: 42003.36,
    close: 42009.94,
    low: 41996.08,
    high: 42017.01,
    volume: 0.216,
    time: 1704114000000
  },
  {
    open: 42009.94,
    close: 41996.19,
    low: 41996.06,
    high: 42017,
    volume: 0.20900000000000002,
    time: 1704117600000
  },
  {
    open: 41996.19,
    close: 41999.57,
    low: 41996.19,
    high: 42017.02,
    volume: 0.209,
    time: 1704121200000
  },
  {
    open: 41999.57,
    close: 42004.81,
    low: 41996.04,
    high: 42017,
    volume: 0.221,
    time: 1704124800000
  },
  {
    open: 42004.81,
    close: 41997.82,
    low: 41996.09,
    high: 42017.02,
    volume: 0.216,
    time: 1704128400000
  },
  {
    open: 41997.82,
    close: 42006.75,
    low: 41996.12,
    high: 42016.9,
    volume: 0.21,
    time: 1704132000000
  },
  {
    open: 42006.75,
    close: 42013.58,
    low: 41996.15,
    high: 42016.97,
    volume: 0.20600000000000002,
    time: 1704135600000
  },
  {
    open: 42013.58,
    close: 42002.32,
    low: 41996.23,
    high: 42016.97,
    volume: 0.214,
    time: 1704139200000
  },
  {
    open: 42002.32,
    close: 42013.19,
    low: 41996.07,
    high: 42017,
    volume: 0.218,
    time: 1704142800000
  },
  {
    open: 42013.19,
    close: 42015.57,
    low: 41996.06,
    high: 42017.01,
    volume: 0.20800000000000002,
    time: 1704146400000
  },
  {
    open: 42015.57,
    close: 42015.35,
    low: 41996.13,
    high: 42016.9,
    volume: 0.20900000000000002,
    time: 1704150000000
  },
  {
    open: 42015.35,
    close: 41997.74,
    low: 41996.06,
    high: 42016.9,
    volume: 0.217,
    time: 1704153600000
  },
  {
    open: 41997.74,
    close: 42016.26,
    low: 41996.1,
    high: 42016.86,
    volume: 0.2,
    time: 1704157200000
  },
  {
    open: 42016.26,
    close: 41999.28,
    low: 41996.27,
    high: 42016.89,
    volume: 0.2,
    time: 1704160800000
  },
  {
    open: 41999.28,
    close: 42011.8,
    low: 41996.06,
    high: 42016.84,
    volume: 0.199,
    time: 1704164400000
  },
  {
    open: 42011.8,
    close: 45198.22,
    low: 42000.48,
    high: 45417.47,
    volume: 0.212,
    time: 1704168000000
  },
  {
    open: 45198.22,
    close: 45193.77,
    low: 45115.41,
    high: 45331.85,
    volume: 0.2,
    time: 1704171600000
  },
  {
    open: 45193.77,
    close: 45148.25,
    low: 45127.29,
    high: 45321.42,
    volume: 0.20700000000000002,
    time: 1704175200000
  },
  {
    open: 45148.25,
    close: 45427.11,
    low: 45148.25,
    high: 45615.22,
    volume: 0.199,
    time: 1704178800000
  },
  {
    open: 45427.11,
    close: 45729.74,
    low: 45312.14,
    high: 45848.31,
    volume: 0.196,
    time: 1704182400000
  },
  {
    open: 45729.74,
    close: 45682.35,
    low: 45619.21,
    high: 45888.7,
    volume: 0.195,
    time: 1704186000000
  },
  {
    open: 45682.35,
    close: 45576.38,
    low: 45426.27,
    high: 45726.05,
    volume: 0.2,
    time: 1704189600000
  },
  {
    open: 45576.38,
    close: 45412.02,
    low: 45207.17,
    high: 45697.14,
    volume: 0.21,
    time: 1704193200000
  },
  {
    open: 45412.02,
    close: 45289.11,
    low: 45266.41,
    high: 45566.99,
    volume: 0.216,
    time: 1704196800000
  },
  {
    open: 45289.11,
    close: 45623.33,
    low: 45275.23,
    high: 45711.2,
    volume: 0.21000000000000002,
    time: 1704200400000
  },
  {
    open: 45623.33,
    close: 45276.66,
    low: 45052.72,
    high: 45808.16,
    volume: 0.214,
    time: 1704204000000
  },
  {
    open: 45276.66,
    close: 45203.74,
    low: 44837.19,
    high: 45334.15,
    volume: 0.217,
    time: 1704207600000
  },
  {
    open: 45203.74,
    close: 45370.97,
    low: 44873.13,
    high: 45400.72,
    volume: 0.20500000000000002,
    time: 1704211200000
  },
  {
    open: 45370.97,
    close: 44943.68,
    low: 44943.68,
    high: 45398.37,
    volume: 0.213,
    time: 1704214800000
  },
  {
    open: 44943.68,
    close: 45154.56,
    low: 44942.31,
    high: 45271.51,
    volume: 0.215,
    time: 1704218400000
  },
  {
    open: 45154.56,
    close: 45049.25,
    low: 44784.68,
    high: 45243.67,
    volume: 0.214,
    time: 1704222000000
  },
  {
    open: 45049.25,
    close: 44805.18,
    low: 44770.31,
    high: 45176.69,
    volume: 0.21600000000000003,
    time: 1704225600000
  },
  {
    open: 44805.18,
    close: 45124.07,
    low: 44636.26,
    high: 45124.07,
    volume: 0.211,
    time: 1704229200000
  },
  {
    open: 45124.07,
    close: 44987.86,
    low: 44853.36,
    high: 45273.84,
    volume: 0.203,
    time: 1704232800000
  },
  {
    open: 44987.86,
    close: 44951.98,
    low: 44797.13,
    high: 45061.07,
    volume: 0.22,
    time: 1704236400000
  },
  {
    open: 44951.98,
    close: 45146.93,
    low: 44864.52,
    high: 45221.39,
    volume: 0.213,
    time: 1704240000000
  },
  {
    open: 45146.93,
    close: 45331.99,
    low: 45102.01,
    high: 45331.99,
    volume: 0.20700000000000002,
    time: 1704243600000
  },
  {
    open: 45331.99,
    close: 45271.56,
    low: 45208.46,
    high: 45409.09,
    volume: 0.186,
    time: 1704247200000
  },
  {
    open: 45271.56,
    close: 45241.93,
    low: 45161.92,
    high: 45325.84,
    volume: 0.20099999999999998,
    time: 1704250800000
  },
  {
    open: 45241.93,
    close: 45185.3,
    low: 45118.41,
    high: 45293.52,
    volume: 0.193,
    time: 1704254400000
  },
  {
    open: 45185.3,
    close: 45183.5,
    low: 45104.05,
    high: 45310.12,
    volume: 0.205,
    time: 1704258000000
  },
  {
    open: 45183.5,
    close: 45301.75,
    low: 45148.13,
    high: 45360.22,
    volume: 0.20400000000000001,
    time: 1704261600000
  },
  {
    open: 45301.75,
    close: 45002.75,
    low: 44933.99,
    high: 45380.35,
    volume: 0.198,
    time: 1704265200000
  },
  {
    open: 45002.75,
    close: 45188.26,
    low: 45002.75,
    high: 45210.04,
    volume: 0.205,
    time: 1704268800000
  },
  {
    open: 45188.26,
    close: 45331.63,
    low: 45179.77,
    high: 45499.2,
    volume: 0.192,
    time: 1704272400000
  },
  {
    open: 45331.63,
    close: 45134.02,
    low: 45044.59,
    high: 45403.09,
    volume: 0.20800000000000002,
    time: 1704276000000
  },
  {
    open: 45134.02,
    close: 43684.94,
    low: 43579.02,
    high: 45181.99,
    volume: 0.205,
    time: 1704279600000
  },
  {
    open: 43684.94,
    close: 42759.73,
    low: 40971.49,
    high: 43700.86,
    volume: 0.20299999999999999,
    time: 1704283200000
  },
  {
    open: 42759.73,
    close: 42496.08,
    low: 42322.2,
    high: 42779.55,
    volume: 0.214,
    time: 1704286800000
  },
  {
    open: 42496.08,
    close: 42165.7,
    low: 42052.82,
    high: 42496.71,
    volume: 0.212,
    time: 1704290400000
  },
  {
    open: 42165.7,
    close: 42969.09,
    low: 42165.7,
    high: 43001.28,
    volume: 0.217,
    time: 1704294000000
  },
  {
    open: 42969.09,
    close: 42963.82,
    low: 42831.85,
    high: 43531.77,
    volume: 0.207,
    time: 1704297600000
  },
  {
    open: 42963.82,
    close: 42861.7,
    low: 42623.92,
    high: 43034.3,
    volume: 0.218,
    time: 1704301200000
  },
  {
    open: 42861.7,
    close: 42237.47,
    low: 42220.14,
    high: 43057.77,
    volume: 0.212,
    time: 1704304800000
  },
  {
    open: 42237.47,
    close: 42612.01,
    low: 42161.46,
    high: 42983.55,
    volume: 0.214,
    time: 1704308400000
  },
  {
    open: 42612.01,
    close: 42758.45,
    low: 42531.27,
    high: 42851.55,
    volume: 0.20700000000000002,
    time: 1704312000000
  },
  {
    open: 42758.45,
    close: 42931.38,
    low: 42600.9,
    high: 42982.85,
    volume: 0.214,
    time: 1704315600000
  },
  {
    open: 42931.38,
    close: 42753.66,
    low: 42629.4,
    high: 42950.18,
    volume: 0.208,
    time: 1704319200000
  },
  {
    open: 42753.66,
    close: 42835.58,
    low: 42584.62,
    high: 42842.08,
    volume: 0.21,
    time: 1704322800000
  },
  {
    open: 42835.58,
    close: 42872.19,
    low: 42755.48,
    high: 42981.11,
    volume: 0.206,
    time: 1704326400000
  },
  {
    open: 42872.19,
    close: 42687.51,
    low: 42602.85,
    high: 42939.24,
    volume: 0.20400000000000001,
    time: 1704330000000
  },
  {
    open: 42687.51,
    close: 43123.53,
    low: 42687.51,
    high: 43194.09,
    volume: 0.2,
    time: 1704333600000
  },
  {
    open: 43123.53,
    close: 43108.58,
    low: 43005.69,
    high: 43241.75,
    volume: 0.2,
    time: 1704337200000
  },
  {
    open: 43108.58,
    close: 43146.67,
    low: 42955.47,
    high: 43202.6,
    volume: 0.196,
    time: 1704340800000
  },
  {
    open: 43146.67,
    close: 43167.52,
    low: 43060.81,
    high: 43276.56,
    volume: 0.20500000000000002,
    time: 1704344400000
  },
  {
    open: 43167.52,
    close: 43137.71,
    low: 43065.24,
    high: 43230.94,
    volume: 0.196,
    time: 1704348000000
  },
  {
    open: 43137.71,
    close: 42794.21,
    low: 42766.41,
    high: 43269.61,
    volume: 0.198,
    time: 1704351600000
  },
  {
    open: 42794.21,
    close: 42867.45,
    low: 42740.26,
    high: 42968.89,
    volume: 0.204,
    time: 1704355200000
  },
  {
    open: 42867.45,
    close: 43024.54,
    low: 42855.78,
    high: 43096.1,
    volume: 0.201,
    time: 1704358800000
  },
  {
    open: 43024.54,
    close: 43070.55,
    low: 42672.54,
    high: 43103.82,
    volume: 0.203,
    time: 1704362400000
  },
  {
    open: 43070.55,
    close: 43183.36,
    low: 42944.22,
    high: 43243.16,
    volume: 0.207,
    time: 1704366000000
  },
  {
    open: 43183.36,
    close: 43371.33,
    low: 43099.14,
    high: 43398.26,
    volume: 0.213,
    time: 1704369600000
  },
  {
    open: 43371.33,
    close: 43372.16,
    low: 43160.02,
    high: 43500.28,
    volume: 0.218,
    time: 1704373200000
  },
  {
    open: 43372.16,
    close: 43662.37,
    low: 43248.45,
    high: 43716.13,
    volume: 0.211,
    time: 1704376800000
  },
  {
    open: 43662.37,
    close: 44060.71,
    low: 43612.85,
    high: 44258.4,
    volume: 0.21,
    time: 1704380400000
  },
  {
    open: 44060.71,
    close: 43920.57,
    low: 43761.11,
    high: 44141.32,
    volume: 0.211,
    time: 1704384000000
  },
  {
    open: 43920.57,
    close: 44007.13,
    low: 43831.07,
    high: 44220.39,
    volume: 0.21,
    time: 1704387600000
  },
  {
    open: 44007.13,
    close: 44066.6,
    low: 43870.04,
    high: 44157.6,
    volume: 0.21300000000000002,
    time: 1704391200000
  },
  {
    open: 44066.6,
    close: 43966.04,
    low: 43932,
    high: 44455.65,
    volume: 0.211,
    time: 1704394800000
  },
  {
    open: 43966.04,
    close: 44105.25,
    low: 43884.82,
    high: 44559.22,
    volume: 0.215,
    time: 1704398400000
  },
  {
    open: 44105.25,
    close: 44436.22,
    low: 44105.25,
    high: 44504.55,
    volume: 0.212,
    time: 1704402000000
  },
  {
    open: 44436.22,
    close: 44302.03,
    low: 44162.79,
    high: 44724.3,
    volume: 0.213,
    time: 1704405600000
  },
  {
    open: 44302.03,
    close: 44163.05,
    low: 44090.26,
    high: 44365.8,
    volume: 0.2,
    time: 1704409200000
  },
  {
    open: 44163.05,
    close: 44231.94,
    low: 43974.44,
    high: 44265.05,
    volume: 0.203,
    time: 1704412800000
  },
  {
    open: 44231.94,
    close: 43377.21,
    low: 42754,
    high: 44301.16,
    volume: 0.214,
    time: 1704416400000
  },
  {
    open: 43377.21,
    close: 43498.81,
    low: 43283.96,
    high: 43802.25,
    volume: 0.197,
    time: 1704420000000
  },
  {
    open: 43498.81,
    close: 43459.83,
    low: 43409.3,
    high: 43728.73,
    volume: 0.195,
    time: 1704423600000
  },
  {
    open: 43459.83,
    close: 43576.15,
    low: 43259.16,
    high: 43637.23,
    volume: 0.198,
    time: 1704427200000
  },
  {
    open: 43576.15,
    close: 43669.95,
    low: 43521.65,
    high: 43730.83,
    volume: 0.20199999999999999,
    time: 1704430800000
  },
  {
    open: 43669.95,
    close: 43822.54,
    low: 43557.05,
    high: 43920.9,
    volume: 0.207,
    time: 1704434400000
  },
  {
    open: 43822.54,
    close: 43789.03,
    low: 43702.5,
    high: 43912.75,
    volume: 0.192,
    time: 1704438000000
  },
  {
    open: 43789.03,
    close: 43891.4,
    low: 43755.14,
    high: 43957.25,
    volume: 0.19899999999999998,
    time: 1704441600000
  },
  {
    open: 43891.4,
    close: 44152.61,
    low: 43891.4,
    high: 44296.53,
    volume: 0.198,
    time: 1704445200000
  },
  {
    open: 44152.61,
    close: 43565.68,
    low: 43520.99,
    high: 44157.97,
    volume: 0.20099999999999998,
    time: 1704448800000
  },
  {
    open: 43565.68,
    close: 43735.19,
    low: 43565.68,
    high: 43908.38,
    volume: 0.203,
    time: 1704452400000
  },
  {
    open: 43735.19,
    close: 43892.05,
    low: 43648.2,
    high: 44088.33,
    volume: 0.211,
    time: 1704456000000
  },
  {
    open: 43892.05,
    close: 44001.05,
    low: 43734.45,
    high: 44065.3,
    volume: 0.213,
    time: 1704459600000
  },
  {
    open: 44001.05,
    close: 43485.86,
    low: 43187.92,
    high: 44061.62,
    volume: 0.216,
    time: 1704463200000
  },
  {
    open: 43485.86,
    close: 43494.68,
    low: 43384.16,
    high: 43792.32,
    volume: 0.211,
    time: 1704466800000
  },
  {
    open: 43494.68,
    close: 43275.95,
    low: 43229.63,
    high: 43596.95,
    volume: 0.22,
    time: 1704470400000
  },
  {
    open: 43275.95,
    close: 43619.37,
    low: 43128.6,
    high: 43631.35,
    volume: 0.219,
    time: 1704474000000
  },
  {
    open: 43619.37,
    close: 43582.02,
    low: 43410.58,
    high: 43663.87,
    volume: 0.211,
    time: 1704477600000
  },
  {
    open: 43582.02,
    close: 43592.03,
    low: 43522.99,
    high: 43799.56,
    volume: 0.208,
    time: 1704481200000
  },
  {
    open: 43592.03,
    close: 43949.51,
    low: 43592.03,
    high: 44205.81,
    volume: 0.211,
    time: 1704484800000
  },
  {
    open: 43949.51,
    close: 43931.37,
    low: 43587.15,
    high: 43977.13,
    volume: 0.208,
    time: 1704488400000
  },
  {
    open: 43931.37,
    close: 43969.01,
    low: 43777.26,
    high: 44010.22,
    volume: 0.213,
    time: 1704492000000
  },
  {
    open: 43969.01,
    close: 44126.82,
    low: 43969.01,
    high: 44319.95,
    volume: 0.21,
    time: 1704495600000
  },
  {
    open: 44126.82,
    close: 44071.64,
    low: 43987.12,
    high: 44236.08,
    volume: 0.218,
    time: 1704499200000
  },
  {
    open: 44071.64,
    close: 43978,
    low: 43901.22,
    high: 44096.87,
    volume: 0.215,
    time: 1704502800000
  },
  {
    open: 43978,
    close: 43911.88,
    low: 43823.23,
    high: 44006.5,
    volume: 0.21100000000000002,
    time: 1704506400000
  },
  {
    open: 43911.88,
    close: 43932.63,
    low: 43808.59,
    high: 44028.09,
    volume: 0.206,
    time: 1704510000000
  },
  {
    open: 43932.63,
    close: 43829.24,
    low: 43829.24,
    high: 43975.8,
    volume: 0.212,
    time: 1704513600000
  },
  {
    open: 43829.24,
    close: 43708.14,
    low: 43585.53,
    high: 43875.19,
    volume: 0.212,
    time: 1704517200000
  },
  {
    open: 43708.14,
    close: 43457.77,
    low: 43418.84,
    high: 43708.14,
    volume: 0.213,
    time: 1704520800000
  },
  {
    open: 43457.77,
    close: 43631.74,
    low: 43387.85,
    high: 43667.5,
    volume: 0.21,
    time: 1704524400000
  },
  {
    open: 43631.74,
    close: 43710.66,
    low: 43535.45,
    high: 43728.77,
    volume: 0.21299999999999997,
    time: 1704528000000
  },
  {
    open: 43710.66,
    close: 43660.86,
    low: 43628.38,
    high: 43766.26,
    volume: 0.218,
    time: 1704531600000
  },
  {
    open: 43660.86,
    close: 43688.73,
    low: 43571.29,
    high: 43759,
    volume: 0.211,
    time: 1704535200000
  },
  {
    open: 43688.73,
    close: 43693.66,
    low: 43609.19,
    high: 43723.33,
    volume: 0.216,
    time: 1704538800000
  },
  {
    open: 43693.66,
    close: 43609.08,
    low: 43540.26,
    high: 43747.91,
    volume: 0.219,
    time: 1704542400000
  },
  {
    open: 43609.08,
    close: 43722.39,
    low: 43531.33,
    high: 43746.06,
    volume: 0.22299999999999998,
    time: 1704546000000
  },
  {
    open: 43722.39,
    close: 43811.77,
    low: 43688.05,
    high: 43935.67,
    volume: 0.216,
    time: 1704549600000
  },
  {
    open: 43811.77,
    close: 43883.96,
    low: 43789.2,
    high: 43915.77,
    volume: 0.212,
    time: 1704553200000
  },
  {
    open: 43883.96,
    close: 43855.59,
    low: 43828.35,
    high: 43984.77,
    volume: 0.213,
    time: 1704556800000
  },
  {
    open: 43855.59,
    close: 43951.65,
    low: 43829.75,
    high: 44014.87,
    volume: 0.219,
    time: 1704560400000
  },
  {
    open: 43951.65,
    close: 43999.53,
    low: 43863.39,
    high: 44146.05,
    volume: 0.206,
    time: 1704564000000
  },
  {
    open: 43999.53,
    close: 43896.33,
    low: 43828.97,
    high: 44014.02,
    volume: 0.219,
    time: 1704567600000
  },
  {
    open: 43896.33,
    close: 43838.23,
    low: 43685.42,
    high: 43980.07,
    volume: 0.212,
    time: 1704571200000
  },
  {
    open: 43838.23,
    close: 43885.46,
    low: 43725.92,
    high: 43936.54,
    volume: 0.222,
    time: 1704574800000
  },
  {
    open: 43885.46,
    close: 43884.43,
    low: 43763.34,
    high: 43938.21,
    volume: 0.20900000000000002,
    time: 1704578400000
  },
  {
    open: 43884.43,
    close: 43947.34,
    low: 43726.37,
    high: 44000.54,
    volume: 0.216,
    time: 1704582000000
  },
  {
    open: 43947.34,
    close: 44119.52,
    low: 43931.92,
    high: 44249.06,
    volume: 0.21000000000000002,
    time: 1704585600000
  },
  {
    open: 44119.52,
    close: 43983.51,
    low: 43962.65,
    high: 44169.81,
    volume: 0.206,
    time: 1704589200000
  },
  {
    open: 43983.51,
    close: 43920.61,
    low: 43845.87,
    high: 44106.76,
    volume: 0.212,
    time: 1704592800000
  },
  {
    open: 43920.61,
    close: 43955.72,
    low: 43897.2,
    high: 44047.03,
    volume: 0.206,
    time: 1704596400000
  },
  {
    open: 43955.72,
    close: 43994.55,
    low: 43871.06,
    high: 44046.63,
    volume: 0.214,
    time: 1704600000000
  },
  {
    open: 43994.55,
    close: 43891.45,
    low: 43857.98,
    high: 44076.74,
    volume: 0.21,
    time: 1704603600000
  },
  {
    open: 43891.45,
    close: 44017.95,
    low: 43851.51,
    high: 44080.34,
    volume: 0.219,
    time: 1704607200000
  },
  {
    open: 44017.95,
    close: 44068.05,
    low: 43979.43,
    high: 44113.92,
    volume: 0.21,
    time: 1704610800000
  },
  {
    open: 44068.05,
    close: 44021.22,
    low: 43990.42,
    high: 44174.83,
    volume: 0.209,
    time: 1704614400000
  },
  {
    open: 44021.22,
    close: 43920.32,
    low: 43877.36,
    high: 44078.92,
    volume: 0.21,
    time: 1704618000000
  },
  {
    open: 43920.32,
    close: 43948.57,
    low: 43901.07,
    high: 44005,
    volume: 0.218,
    time: 1704621600000
  },
  {
    open: 43948.57,
    close: 44063.27,
    low: 43948.57,
    high: 44128.45,
    volume: 0.213,
    time: 1704625200000
  },
  {
    open: 44063.27,
    close: 44073.84,
    low: 43958.19,
    high: 44149.41,
    volume: 0.211,
    time: 1704628800000
  },
  {
    open: 44073.84,
    close: 44394.22,
    low: 44073.73,
    high: 44422.47,
    volume: 0.212,
    time: 1704632400000
  },
  {
    open: 44394.22,
    close: 44377.31,
    low: 44272.91,
    high: 44485.26,
    volume: 0.21300000000000002,
    time: 1704636000000
  },
  {
    open: 44377.31,
    close: 44041.63,
    low: 43946.95,
    high: 44447.75,
    volume: 0.215,
    time: 1704639600000
  },
  {
    open: 44041.63,
    close: 43931.23,
    low: 43859.68,
    high: 44198.01,
    volume: 0.212,
    time: 1704643200000
  },
  {
    open: 43931.23,
    close: 44079.47,
    low: 43789.51,
    high: 44090.71,
    volume: 0.206,
    time: 1704646800000
  },
  {
    open: 44079.47,
    close: 44101.57,
    low: 44029.14,
    high: 44189.71,
    volume: 0.216,
    time: 1704650400000
  },
  {
    open: 44101.57,
    close: 44254.72,
    low: 44020.07,
    high: 44257.5,
    volume: 0.212,
    time: 1704654000000
  },
  {
    open: 44254.72,
    close: 44123.35,
    low: 44121.05,
    high: 44285.44,
    volume: 0.206,
    time: 1704657600000
  },
  {
    open: 44123.35,
    close: 44231.33,
    low: 44112.38,
    high: 44249.01,
    volume: 0.21100000000000002,
    time: 1704661200000
  },
  {
    open: 44231.33,
    close: 43871.88,
    low: 43776.87,
    high: 44231.33,
    volume: 0.215,
    time: 1704664800000
  },
  {
    open: 43871.88,
    close: 43919.66,
    low: 43611.12,
    high: 44036.53,
    volume: 0.209,
    time: 1704668400000
  },
  {
    open: 43919.66,
    close: 43655.18,
    low: 43647.57,
    high: 43983.55,
    volume: 0.208,
    time: 1704672000000
  },
  {
    open: 43655.18,
    close: 43720.44,
    low: 43633.74,
    high: 43822.33,
    volume: 0.20400000000000001,
    time: 1704675600000
  },
  {
    open: 43720.44,
    close: 43332.56,
    low: 43179.43,
    high: 43811.53,
    volume: 0.201,
    time: 1704679200000
  },
  {
    open: 43332.56,
    close: 43506.86,
    low: 43215.92,
    high: 43573.35,
    volume: 0.19899999999999998,
    time: 1704682800000
  },
  {
    open: 43506.86,
    close: 43602.79,
    low: 43431.15,
    high: 43614.82,
    volume: 0.199,
    time: 1704686400000
  },
  {
    open: 43602.79,
    close: 43625.5,
    low: 43466.25,
    high: 43660.59,
    volume: 0.199,
    time: 1704690000000
  },
  {
    open: 43625.5,
    close: 43920.89,
    low: 43585.13,
    high: 44216.63,
    volume: 0.20700000000000002,
    time: 1704693600000
  },
  {
    open: 43920.89,
    close: 43966.09,
    low: 43788.04,
    high: 44090.06,
    volume: 0.196,
    time: 1704697200000
  },
  {
    open: 43966.09,
    close: 43896.03,
    low: 43840.2,
    high: 44074.13,
    volume: 0.201,
    time: 1704700800000
  },
  {
    open: 43896.03,
    close: 43693.58,
    low: 43654.68,
    high: 43918.52,
    volume: 0.203,
    time: 1704704400000
  },
  {
    open: 43693.58,
    close: 44103.98,
    low: 43679.22,
    high: 44130.01,
    volume: 0.204,
    time: 1704708000000
  },
  {
    open: 44103.98,
    close: 44631.85,
    low: 43969.11,
    high: 44631.88,
    volume: 0.209,
    time: 1704711600000
  },
  {
    open: 44631.85,
    close: 45088.95,
    low: 44501.38,
    high: 45181.53,
    volume: 0.213,
    time: 1704715200000
  },
  {
    open: 45088.95,
    close: 44892.68,
    low: 44834.18,
    high: 45181.21,
    volume: 0.213,
    time: 1704718800000
  },
  {
    open: 44892.68,
    close: 44781.91,
    low: 44747.77,
    high: 45087.22,
    volume: 0.213,
    time: 1704722400000
  },
  {
    open: 44781.91,
    close: 45001.48,
    low: 44663.93,
    high: 45258.42,
    volume: 0.214,
    time: 1704726000000
  },
  {
    open: 45001.48,
    close: 44927.69,
    low: 44801.63,
    high: 45153.73,
    volume: 0.214,
    time: 1704729600000
  },
  {
    open: 44927.69,
    close: 45676.87,
    low: 44896.31,
    high: 45676.87,
    volume: 0.21799999999999997,
    time: 1704733200000
  },
  {
    open: 45676.87,
    close: 46925.36,
    low: 45676.87,
    high: 46949.91,
    volume: 0.216,
    time: 1704736800000
  },
  {
    open: 46925.36,
    close: 46970.27,
    low: 46642.83,
    high: 47212.08,
    volume: 0.214,
    time: 1704740400000
  },
  {
    open: 46970.27,
    close: 46759.46,
    low: 46745.97,
    high: 47144.88,
    volume: 0.211,
    time: 1704744000000
  },
  {
    open: 46759.46,
    close: 47038.61,
    low: 46658.39,
    high: 47209.95,
    volume: 0.21200000000000002,
    time: 1704747600000
  },
  {
    open: 47038.61,
    close: 47008.54,
    low: 46832.89,
    high: 47121.51,
    volume: 0.2,
    time: 1704751200000
  },
  {
    open: 47008.54,
    close: 46947.08,
    low: 46823.5,
    high: 47112.51,
    volume: 0.208,
    time: 1704754800000
  },
  {
    open: 46947.08,
    close: 46694.34,
    low: 46294.81,
    high: 47144.78,
    volume: 0.208,
    time: 1704758400000
  },
  {
    open: 46694.34,
    close: 46556.09,
    low: 46292.67,
    high: 46796.58,
    volume: 0.209,
    time: 1704762000000
  },
  {
    open: 46556.09,
    close: 46773.31,
    low: 46470.82,
    high: 46800.02,
    volume: 0.203,
    time: 1704765600000
  },
  {
    open: 46773.31,
    close: 46926.36,
    low: 46742.34,
    high: 46980.71,
    volume: 0.20299999999999999,
    time: 1704769200000
  },
  {
    open: 46926.36,
    close: 46785.24,
    low: 46674.08,
    high: 46938.83,
    volume: 0.197,
    time: 1704772800000
  },
  {
    open: 46785.24,
    close: 46733.78,
    low: 46593.09,
    high: 46862.66,
    volume: 0.199,
    time: 1704776400000
  },
  {
    open: 46733.78,
    close: 46806.65,
    low: 46651.86,
    high: 46853,
    volume: 0.203,
    time: 1704780000000
  },
  {
    open: 46806.65,
    close: 46837.93,
    low: 46717.17,
    high: 46940.4,
    volume: 0.20299999999999999,
    time: 1704783600000
  },
  {
    open: 46837.93,
    close: 46563.77,
    low: 46536.74,
    high: 46893.38,
    volume: 0.196,
    time: 1704787200000
  },
  {
    open: 46563.77,
    close: 46490.37,
    low: 46456.3,
    high: 46683.56,
    volume: 0.195,
    time: 1704790800000
  },
  {
    open: 46490.37,
    close: 46430.23,
    low: 46392.76,
    high: 46684.38,
    volume: 0.205,
    time: 1704794400000
  },
  {
    open: 46430.23,
    close: 46652.82,
    low: 46404.54,
    high: 46652.82,
    volume: 0.213,
    time: 1704798000000
  },
  {
    open: 46652.82,
    close: 46188.15,
    low: 45871.57,
    high: 46970.33,
    volume: 0.215,
    time: 1704801600000
  },
  {
    open: 46188.15,
    close: 46822.04,
    low: 46143.66,
    high: 47006.17,
    volume: 0.216,
    time: 1704805200000
  },
  {
    open: 46822.04,
    close: 46598.55,
    low: 46505.5,
    high: 46971.87,
    volume: 0.218,
    time: 1704808800000
  },
  {
    open: 46598.55,
    close: 46842.85,
    low: 46429.85,
    high: 46999.11,
    volume: 0.203,
    time: 1704812400000
  },
  {
    open: 46842.85,
    close: 46920.62,
    low: 46754,
    high: 46973.09,
    volume: 0.211,
    time: 1704816000000
  },
  {
    open: 46920.62,
    close: 46688.96,
    low: 46622.59,
    high: 46920.62,
    volume: 0.212,
    time: 1704819600000
  },
  {
    open: 46688.96,
    close: 46620.74,
    low: 46483.31,
    high: 46726.1,
    volume: 0.21,
    time: 1704823200000
  },
  {
    open: 46620.74,
    close: 46893.87,
    low: 46564.92,
    high: 46893.87,
    volume: 0.213,
    time: 1704826800000
  },
  {
    open: 46893.87,
    close: 46639.21,
    low: 46595.06,
    high: 46938.94,
    volume: 0.20500000000000002,
    time: 1704830400000
  },
  {
    open: 46639.21,
    close: 45477.83,
    low: 45177.18,
    high: 47967.83,
    volume: 0.214,
    time: 1704834000000
  },
  {
    open: 45477.83,
    close: 46273.87,
    low: 45311.49,
    high: 46273.87,
    volume: 0.20700000000000002,
    time: 1704837600000
  },
  {
    open: 46273.87,
    close: 46095.28,
    low: 45938.66,
    high: 46273.87,
    volume: 0.212,
    time: 1704841200000
  },
  {
    open: 46095.28,
    close: 45848.24,
    low: 45790.14,
    high: 46248.19,
    volume: 0.215,
    time: 1704844800000
  },
  {
    open: 45848.24,
    close: 45939.98,
    low: 45625.72,
    high: 45960.31,
    volume: 0.206,
    time: 1704848400000
  },
  {
    open: 45939.98,
    close: 45943.07,
    low: 45872.24,
    high: 46085.53,
    volume: 0.196,
    time: 1704852000000
  },
  {
    open: 45943.07,
    close: 46117.19,
    low: 45920.67,
    high: 46213.46,
    volume: 0.2,
    time: 1704855600000
  },
  {
    open: 46117.19,
    close: 45965.94,
    low: 45961.27,
    high: 46194.55,
    volume: 0.2,
    time: 1704859200000
  },
  {
    open: 45965.94,
    close: 45972.11,
    low: 45899.63,
    high: 46019.15,
    volume: 0.21,
    time: 1704862800000
  },
  {
    open: 45972.11,
    close: 45990.12,
    low: 45875.86,
    high: 46076.53,
    volume: 0.211,
    time: 1704866400000
  },
  {
    open: 45990.12,
    close: 45765.6,
    low: 45746.81,
    high: 46037.62,
    volume: 0.195,
    time: 1704870000000
  },
  {
    open: 45765.6,
    close: 45352.8,
    low: 45352.8,
    high: 45865.8,
    volume: 0.2,
    time: 1704873600000
  },
  {
    open: 45352.8,
    close: 45633.31,
    low: 45326.63,
    high: 45685.54,
    volume: 0.201,
    time: 1704877200000
  },
  {
    open: 45633.31,
    close: 45532.52,
    low: 45529.41,
    high: 45746.59,
    volume: 0.203,
    time: 1704880800000
  },
  {
    open: 45532.52,
    close: 45534.41,
    low: 45116.28,
    high: 45674.82,
    volume: 0.209,
    time: 1704884400000
  },
  {
    open: 45534.41,
    close: 44668.81,
    low: 44405.19,
    high: 45639.8,
    volume: 0.212,
    time: 1704888000000
  },
  {
    open: 44668.81,
    close: 45103.12,
    low: 44433.55,
    high: 45356.83,
    volume: 0.212,
    time: 1704891600000
  },
  {
    open: 45103.12,
    close: 44899.31,
    low: 44828.05,
    high: 45383.67,
    volume: 0.212,
    time: 1704895200000
  },
  {
    open: 44899.31,
    close: 45529.57,
    low: 44823.79,
    high: 45529.57,
    volume: 0.21,
    time: 1704898800000
  },
  {
    open: 45529.57,
    close: 45332.68,
    low: 45175.38,
    high: 45681.55,
    volume: 0.21,
    time: 1704902400000
  },
  {
    open: 45332.68,
    close: 46382.75,
    low: 45251.28,
    high: 46449.45,
    volume: 0.213,
    time: 1704906000000
  },
  {
    open: 46382.75,
    close: 46533.01,
    low: 46050.13,
    high: 46599.39,
    volume: 0.219,
    time: 1704909600000
  },
  {
    open: 46533.01,
    close: 46508.75,
    low: 46132.34,
    high: 46649.82,
    volume: 0.211,
    time: 1704913200000
  },
  {
    open: 46508.75,
    close: 46129.97,
    low: 45101.3,
    high: 46508.75,
    volume: 0.213,
    time: 1704916800000
  },
  {
    open: 46129.97,
    close: 45925.6,
    low: 45240.88,
    high: 46259.86,
    volume: 0.215,
    time: 1704920400000
  },
  {
    open: 45925.6,
    close: 46886.2,
    low: 45749.37,
    high: 46946.34,
    volume: 0.21300000000000002,
    time: 1704924000000
  },
  {
    open: 46886.2,
    close: 46671.23,
    low: 46450.78,
    high: 47684.49,
    volume: 0.212,
    time: 1704927600000
  },
  {
    open: 46671.23,
    close: 46695.23,
    low: 46299.34,
    high: 46706.54,
    volume: 0.21,
    time: 1704931200000
  },
  {
    open: 46695.23,
    close: 46487.73,
    low: 46463.62,
    high: 46756.76,
    volume: 0.201,
    time: 1704934800000
  },
  {
    open: 46487.73,
    close: 46576.13,
    low: 46478.18,
    high: 46635.88,
    volume: 0.198,
    time: 1704938400000
  },
  {
    open: 46576.13,
    close: 46496.76,
    low: 46241.97,
    high: 46592.87,
    volume: 0.199,
    time: 1704942000000
  },
  {
    open: 46496.76,
    close: 46393.72,
    low: 46312.89,
    high: 46508.38,
    volume: 0.2,
    time: 1704945600000
  },
  {
    open: 46393.72,
    close: 46054.66,
    low: 45933.33,
    high: 46435.77,
    volume: 0.196,
    time: 1704949200000
  },
  {
    open: 46054.66,
    close: 45897.36,
    low: 45625.46,
    high: 46092.49,
    volume: 0.208,
    time: 1704952800000
  },
  {
    open: 45897.36,
    close: 46079.9,
    low: 45891.71,
    high: 46174.29,
    volume: 0.195,
    time: 1704956400000
  },
  {
    open: 46079.9,
    close: 46255.51,
    low: 46044,
    high: 46326.08,
    volume: 0.195,
    time: 1704960000000
  },
  {
    open: 46255.51,
    close: 46205.04,
    low: 46199.27,
    high: 46369.82,
    volume: 0.198,
    time: 1704963600000
  },
  {
    open: 46205.04,
    close: 46835.38,
    low: 46203.5,
    high: 46857.64,
    volume: 0.2,
    time: 1704967200000
  },
  {
    open: 46835.38,
    close: 47034.11,
    low: 46727.09,
    high: 47146.08,
    volume: 0.206,
    time: 1704970800000
  },
  {
    open: 47034.11,
    close: 47468.19,
    low: 45571,
    high: 47468.19,
    volume: 0.223,
    time: 1704974400000
  },
  {
    open: 47468.19,
    close: 47300.91,
    low: 46844.93,
    high: 47468.19,
    volume: 0.20900000000000002,
    time: 1704978000000
  },
  {
    open: 47300.91,
    close: 48612.25,
    low: 47232.94,
    high: 48951.69,
    volume: 0.21399999999999997,
    time: 1704981600000
  },
  {
    open: 48612.25,
    close: 47380.7,
    low: 47289.52,
    high: 48663.46,
    volume: 0.149,
    time: 1704985200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1704988800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1704992400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1704996000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1704999600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705003200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705006800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705010400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705014000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705017600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705021200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705024800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705028400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705032000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705035600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705039200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705042800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705046400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705050000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705053600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705057200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705060800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705064400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705068000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705071600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705075200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705078800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705082400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705086000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705089600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705093200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705096800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705100400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705104000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705107600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705111200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705114800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705118400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705122000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705125600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705129200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705132800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705136400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705140000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705143600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705147200000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705150800000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705154400000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705158000000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705161600000
  },
  {
    open: 47380.7,
    close: 47380.7,
    low: 47380.7,
    high: 47380.7,
    volume: 0,
    time: 1705165200000
  },
  {
    open: 47380.7,
    close: 42799.85,
    low: 42761.22,
    high: 47380.7,
    volume: 0.13,
    time: 1705168800000
  },
  {
    open: 42799.85,
    close: 42774.59,
    low: 42774.59,
    high: 43005.64,
    volume: 0.21800000000000003,
    time: 1705172400000
  },
  {
    open: 42774.59,
    close: 42875.66,
    low: 42699.92,
    high: 42928.2,
    volume: 0.21399999999999997,
    time: 1705176000000
  },
  {
    open: 42875.66,
    close: 42962.95,
    low: 42804.09,
    high: 42990.91,
    volume: 0.213,
    time: 1705179600000
  },
  {
    open: 42962.95,
    close: 43008.66,
    low: 42875.92,
    high: 43037.13,
    volume: 0.212,
    time: 1705183200000
  },
  {
    open: 43008.66,
    close: 42848.75,
    low: 42792.58,
    high: 43008.66,
    volume: 0.217,
    time: 1705186800000
  },
  {
    open: 42848.75,
    close: 42680.02,
    low: 42667.82,
    high: 42896.71,
    volume: 0.208,
    time: 1705190400000
  },
  {
    open: 42680.02,
    close: 42759.41,
    low: 42675.79,
    high: 42849.94,
    volume: 0.20900000000000002,
    time: 1705194000000
  },
  {
    open: 42759.41,
    close: 42779.41,
    low: 42650.22,
    high: 42840.66,
    volume: 0.211,
    time: 1705197600000
  },
  {
    open: 42779.41,
    close: 42665.29,
    low: 42643.34,
    high: 42808.19,
    volume: 0.214,
    time: 1705201200000
  },
  {
    open: 42665.29,
    close: 42597.83,
    low: 42548.29,
    high: 42696.89,
    volume: 0.21,
    time: 1705204800000
  },
  {
    open: 42597.83,
    close: 42764.1,
    low: 42565.17,
    high: 42807.53,
    volume: 0.216,
    time: 1705208400000
  },
  {
    open: 42764.1,
    close: 43003.77,
    low: 42749.92,
    high: 43008.34,
    volume: 0.21400000000000002,
    time: 1705212000000
  },
  {
    open: 43003.77,
    close: 43020.56,
    low: 42879.57,
    high: 43073.26,
    volume: 0.196,
    time: 1705215600000
  },
  {
    open: 43020.56,
    close: 43003.95,
    low: 42891.35,
    high: 43045.13,
    volume: 0.20600000000000002,
    time: 1705219200000
  },
  {
    open: 43003.95,
    close: 43062.84,
    low: 42812.04,
    high: 43094.39,
    volume: 0.20400000000000001,
    time: 1705222800000
  },
  {
    open: 43062.84,
    close: 42878.34,
    low: 42871.04,
    high: 43062.84,
    volume: 0.20700000000000002,
    time: 1705226400000
  },
  {
    open: 42878.34,
    close: 42845.84,
    low: 42762.84,
    high: 42958.35,
    volume: 0.21799999999999997,
    time: 1705230000000
  },
  {
    open: 42845.84,
    close: 42737.53,
    low: 42718.41,
    high: 42862.15,
    volume: 0.215,
    time: 1705233600000
  },
  {
    open: 42737.53,
    close: 42871.24,
    low: 42737.09,
    high: 42919.91,
    volume: 0.20600000000000002,
    time: 1705237200000
  },
  {
    open: 42871.24,
    close: 42811.94,
    low: 42780.99,
    high: 43015.89,
    volume: 0.22,
    time: 1705240800000
  },
  {
    open: 42811.94,
    close: 42933.38,
    low: 42793.4,
    high: 42971.5,
    volume: 0.2,
    time: 1705244400000
  },
  {
    open: 42933.38,
    close: 42945.77,
    low: 42845.77,
    high: 42977.41,
    volume: 0.213,
    time: 1705248000000
  },
  {
    open: 42945.77,
    close: 42991.7,
    low: 42871.21,
    high: 43021.5,
    volume: 0.214,
    time: 1705251600000
  },
  {
    open: 42991.7,
    close: 42906.13,
    low: 42830.77,
    high: 43019.49,
    volume: 0.209,
    time: 1705255200000
  },
  {
    open: 42906.13,
    close: 42654.98,
    low: 42640.73,
    high: 42943.2,
    volume: 0.212,
    time: 1705258800000
  },
  {
    open: 42654.98,
    close: 42528.75,
    low: 42349.46,
    high: 42752.63,
    volume: 0.215,
    time: 1705262400000
  },
  {
    open: 42528.75,
    close: 42507.1,
    low: 42074.94,
    high: 42603.85,
    volume: 0.20600000000000002,
    time: 1705266000000
  },
  {
    open: 42507.1,
    close: 42238.4,
    low: 42087.37,
    high: 42641.3,
    volume: 0.20800000000000002,
    time: 1705269600000
  },
  {
    open: 42238.4,
    close: 41744.01,
    low: 41713.1,
    high: 42334.72,
    volume: 0.21100000000000002,
    time: 1705273200000
  },
  {
    open: 41744.01,
    close: 42273.91,
    low: 41704.83,
    high: 42346.26,
    volume: 0.211,
    time: 1705276800000
  },
  {
    open: 42273.91,
    close: 42298.47,
    low: 42170.32,
    high: 42392.38,
    volume: 0.20400000000000001,
    time: 1705280400000
  },
  {
    open: 42298.47,
    close: 42387.12,
    low: 42183.87,
    high: 42400.4,
    volume: 0.20700000000000002,
    time: 1705284000000
  },
  {
    open: 42387.12,
    close: 42552.07,
    low: 42383.09,
    high: 42644.29,
    volume: 0.199,
    time: 1705287600000
  },
  {
    open: 42552.07,
    close: 42574.53,
    low: 42483.66,
    high: 42646.38,
    volume: 0.20800000000000002,
    time: 1705291200000
  },
  {
    open: 42574.53,
    close: 42750.22,
    low: 42553.76,
    high: 42814.62,
    volume: 0.20500000000000002,
    time: 1705294800000
  },
  {
    open: 42750.22,
    close: 42669.35,
    low: 42609.38,
    high: 42791.21,
    volume: 0.214,
    time: 1705298400000
  },
  {
    open: 42669.35,
    close: 42732,
    low: 42511.84,
    high: 42771.98,
    volume: 0.196,
    time: 1705302000000
  },
  {
    open: 42732,
    close: 42666.26,
    low: 42629.53,
    high: 42792.32,
    volume: 0.201,
    time: 1705305600000
  },
  {
    open: 42666.26,
    close: 42734.85,
    low: 42592.89,
    high: 42767.6,
    volume: 0.2,
    time: 1705309200000
  },
  {
    open: 42734.85,
    close: 42555.06,
    low: 42543.26,
    high: 42737.5,
    volume: 0.196,
    time: 1705312800000
  },
  {
    open: 42555.06,
    close: 42713.66,
    low: 42532.83,
    high: 42720.86,
    volume: 0.213,
    time: 1705316400000
  },
  {
    open: 42713.66,
    close: 42647.54,
    low: 42578.06,
    high: 42728.41,
    volume: 0.221,
    time: 1705320000000
  },
  {
    open: 42647.54,
    close: 42862.13,
    low: 42614.22,
    high: 42961.11,
    volume: 0.20700000000000002,
    time: 1705323600000
  },
  {
    open: 42862.13,
    close: 42182.35,
    low: 42178.34,
    high: 42873.78,
    volume: 0.206,
    time: 1705327200000
  },
  {
    open: 42182.35,
    close: 42266.96,
    low: 42182.35,
    high: 42481.12,
    volume: 0.21000000000000002,
    time: 1705330800000
  },
  {
    open: 42266.96,
    close: 42524.47,
    low: 42239.62,
    high: 42563.27,
    volume: 0.213,
    time: 1705334400000
  },
  {
    open: 42524.47,
    close: 42726.34,
    low: 42524.47,
    high: 42782.44,
    volume: 0.216,
    time: 1705338000000
  },
  {
    open: 42726.34,
    close: 42993.09,
    low: 42696.62,
    high: 43380.62,
    volume: 0.21,
    time: 1705341600000
  },
  {
    open: 42993.09,
    close: 42969.79,
    low: 42819.35,
    high: 43136.8,
    volume: 0.21,
    time: 1705345200000
  },
  {
    open: 42969.79,
    close: 42950.85,
    low: 42836.22,
    high: 43124.94,
    volume: 0.217,
    time: 1705348800000
  },
  {
    open: 42950.85,
    close: 42729.56,
    low: 42729.56,
    high: 42967.92,
    volume: 0.215,
    time: 1705352400000
  },
  {
    open: 42729.56,
    close: 42602.02,
    low: 42366.03,
    high: 42820.7,
    volume: 0.209,
    time: 1705356000000
  },
  {
    open: 42602.02,
    close: 42515.56,
    low: 42463.3,
    high: 42646.45,
    volume: 0.212,
    time: 1705359600000
  },
  {
    open: 42515.56,
    close: 42599.16,
    low: 42449.94,
    high: 42678.71,
    volume: 0.216,
    time: 1705363200000
  },
  {
    open: 42599.16,
    close: 42563.41,
    low: 42550.46,
    high: 42728.11,
    volume: 0.206,
    time: 1705366800000
  },
  {
    open: 42563.41,
    close: 42699.75,
    low: 42545.88,
    high: 42750.2,
    volume: 0.20199999999999999,
    time: 1705370400000
  },
  {
    open: 42699.75,
    close: 42937.54,
    low: 42690.84,
    high: 42958.68,
    volume: 0.19499999999999998,
    time: 1705374000000
  },
  {
    open: 42937.54,
    close: 42812.92,
    low: 42746.31,
    high: 42963.38,
    volume: 0.194,
    time: 1705377600000
  },
  {
    open: 42812.92,
    close: 42793.52,
    low: 42759.54,
    high: 42938.02,
    volume: 0.192,
    time: 1705381200000
  },
  {
    open: 42793.52,
    close: 42737.08,
    low: 42647.01,
    high: 42794.16,
    volume: 0.207,
    time: 1705384800000
  },
  {
    open: 42737.08,
    close: 42780.51,
    low: 42598.98,
    high: 42801.01,
    volume: 0.20500000000000002,
    time: 1705388400000
  },
  {
    open: 42780.51,
    close: 43003.76,
    low: 42701,
    high: 43036.02,
    volume: 0.193,
    time: 1705392000000
  },
  {
    open: 43003.76,
    close: 42956.59,
    low: 42901.15,
    high: 43160.06,
    volume: 0.197,
    time: 1705395600000
  },
  {
    open: 42956.59,
    close: 42872.69,
    low: 42832.64,
    high: 42964.62,
    volume: 0.2,
    time: 1705399200000
  },
  {
    open: 42872.69,
    close: 42892.37,
    low: 42758.68,
    high: 42960.45,
    volume: 0.20700000000000002,
    time: 1705402800000
  },
  {
    open: 42892.37,
    close: 43155.43,
    low: 42846.5,
    high: 43171.31,
    volume: 0.215,
    time: 1705406400000
  },
  {
    open: 43155.43,
    close: 42796.95,
    low: 42764.36,
    high: 43160.79,
    volume: 0.205,
    time: 1705410000000
  },
  {
    open: 42796.95,
    close: 42687.12,
    low: 42086.72,
    high: 42920.17,
    volume: 0.204,
    time: 1705413600000
  },
  {
    open: 42687.12,
    close: 43262.34,
    low: 42533.52,
    high: 43316.52,
    volume: 0.216,
    time: 1705417200000
  },
  {
    open: 43262.34,
    close: 43266,
    low: 43000.85,
    high: 43407.88,
    volume: 0.213,
    time: 1705420800000
  },
  {
    open: 43266,
    close: 43072.78,
    low: 43029.39,
    high: 43266,
    volume: 0.215,
    time: 1705424400000
  },
  {
    open: 43072.78,
    close: 43032.75,
    low: 42839.49,
    high: 43168.1,
    volume: 0.207,
    time: 1705428000000
  },
  {
    open: 43032.75,
    close: 43164.55,
    low: 42969.92,
    high: 43200.8,
    volume: 0.216,
    time: 1705431600000
  },
  {
    open: 43164.55,
    close: 43194.89,
    low: 43064.88,
    high: 43377.38,
    volume: 0.216,
    time: 1705435200000
  },
  {
    open: 43194.89,
    close: 43457.59,
    low: 43175.78,
    high: 43570.27,
    volume: 0.215,
    time: 1705438800000
  },
  {
    open: 43457.59,
    close: 43184.74,
    low: 43184.74,
    high: 43497.69,
    volume: 0.217,
    time: 1705442400000
  },
  {
    open: 43184.74,
    close: 43154.37,
    low: 43111.4,
    high: 43285.84,
    volume: 0.217,
    time: 1705446000000
  },
  {
    open: 43154.37,
    close: 43109.86,
    low: 43041.87,
    high: 43187.32,
    volume: 0.21,
    time: 1705449600000
  },
  {
    open: 43109.86,
    close: 42970.59,
    low: 42955.33,
    high: 43208.92,
    volume: 0.205,
    time: 1705453200000
  },
  {
    open: 42970.59,
    close: 42984.66,
    low: 42901.16,
    high: 43071.1,
    volume: 0.196,
    time: 1705456800000
  },
  {
    open: 42984.66,
    close: 42873.37,
    low: 42837.15,
    high: 43040.21,
    volume: 0.199,
    time: 1705460400000
  },
  {
    open: 42873.37,
    close: 42860.61,
    low: 42781.37,
    high: 42917.99,
    volume: 0.20400000000000001,
    time: 1705464000000
  },
  {
    open: 42860.61,
    close: 42792.64,
    low: 42757.68,
    high: 42936.12,
    volume: 0.198,
    time: 1705467600000
  },
  {
    open: 42792.64,
    close: 42796.15,
    low: 42768.21,
    high: 42885.2,
    volume: 0.198,
    time: 1705471200000
  },
  {
    open: 42796.15,
    close: 42653.19,
    low: 42604.39,
    high: 42870.32,
    volume: 0.2,
    time: 1705474800000
  },
  {
    open: 42653.19,
    close: 42730.68,
    low: 42557.98,
    high: 42758.5,
    volume: 0.195,
    time: 1705478400000
  },
  {
    open: 42730.68,
    close: 42659.51,
    low: 42659.51,
    high: 42840.44,
    volume: 0.2,
    time: 1705482000000
  },
  {
    open: 42659.51,
    close: 42829.93,
    low: 42602.83,
    high: 42858.1,
    volume: 0.201,
    time: 1705485600000
  },
  {
    open: 42829.93,
    close: 42673.86,
    low: 42656.87,
    high: 42862.45,
    volume: 0.201,
    time: 1705489200000
  },
  {
    open: 42673.86,
    close: 42762.22,
    low: 42478.07,
    high: 42793.28,
    volume: 0.203,
    time: 1705492800000
  },
  {
    open: 42762.22,
    close: 42640.84,
    low: 42523.19,
    high: 42781.39,
    volume: 0.211,
    time: 1705496400000
  },
  {
    open: 42640.84,
    close: 42568.06,
    low: 42380.72,
    high: 42911.01,
    volume: 0.21699999999999997,
    time: 1705500000000
  },
  {
    open: 42568.06,
    close: 42504.91,
    low: 42481.87,
    high: 42773.33,
    volume: 0.218,
    time: 1705503600000
  },
  {
    open: 42504.91,
    close: 42338.8,
    low: 42285.3,
    high: 42568.81,
    volume: 0.215,
    time: 1705507200000
  },
  {
    open: 42338.8,
    close: 42373.59,
    low: 42202.46,
    high: 42471.7,
    volume: 0.20700000000000002,
    time: 1705510800000
  },
  {
    open: 42373.59,
    close: 42629.76,
    low: 42268.43,
    high: 42629.76,
    volume: 0.211,
    time: 1705514400000
  },
  {
    open: 42629.76,
    close: 42610.72,
    low: 42551.37,
    high: 42760.15,
    volume: 0.20800000000000002,
    time: 1705518000000
  },
  {
    open: 42610.72,
    close: 42723.98,
    low: 42583.77,
    high: 42878.21,
    volume: 0.211,
    time: 1705521600000
  },
  {
    open: 42723.98,
    close: 42677.43,
    low: 42607.44,
    high: 42818.06,
    volume: 0.203,
    time: 1705525200000
  },
  {
    open: 42677.43,
    close: 42731.36,
    low: 42470.61,
    high: 42747.92,
    volume: 0.21,
    time: 1705528800000
  },
  {
    open: 42731.36,
    close: 42791.47,
    low: 42617.53,
    high: 42805.05,
    volume: 0.213,
    time: 1705532400000
  },
  {
    open: 42791.47,
    close: 42680.15,
    low: 42622.91,
    high: 42797.39,
    volume: 0.215,
    time: 1705536000000
  },
  {
    open: 42680.15,
    close: 42583.19,
    low: 42499.7,
    high: 42713.42,
    volume: 0.206,
    time: 1705539600000
  },
  {
    open: 42583.19,
    close: 42663.98,
    low: 42577.95,
    high: 42734.96,
    volume: 0.194,
    time: 1705543200000
  },
  {
    open: 42663.98,
    close: 42584.1,
    low: 42541.6,
    high: 42691.45,
    volume: 0.20400000000000001,
    time: 1705546800000
  },
  {
    open: 42584.1,
    close: 42646.24,
    low: 42570.85,
    high: 42706.11,
    volume: 0.201,
    time: 1705550400000
  },
  {
    open: 42646.24,
    close: 42793.85,
    low: 42627.45,
    high: 42793.85,
    volume: 0.207,
    time: 1705554000000
  },
  {
    open: 42793.85,
    close: 42793.8,
    low: 42743.85,
    high: 42889.23,
    volume: 0.201,
    time: 1705557600000
  },
  {
    open: 42793.8,
    close: 42932.2,
    low: 42793.8,
    high: 42937.31,
    volume: 0.2,
    time: 1705561200000
  },
  {
    open: 42932.2,
    close: 42747.79,
    low: 42711.57,
    high: 42940.09,
    volume: 0.195,
    time: 1705564800000
  },
  {
    open: 42747.79,
    close: 42752.62,
    low: 42706.82,
    high: 42882.22,
    volume: 0.19999999999999998,
    time: 1705568400000
  },
  {
    open: 42752.62,
    close: 42443.75,
    low: 42396.42,
    high: 42777.44,
    volume: 0.202,
    time: 1705572000000
  },
  {
    open: 42443.75,
    close: 42565.72,
    low: 42390.86,
    high: 42565.72,
    volume: 0.203,
    time: 1705575600000
  },
  {
    open: 42565.72,
    close: 42437.29,
    low: 42356.74,
    high: 42565.72,
    volume: 0.217,
    time: 1705579200000
  },
  {
    open: 42437.29,
    close: 42512.82,
    low: 42349.23,
    high: 42650.59,
    volume: 0.217,
    time: 1705582800000
  },
  {
    open: 42512.82,
    close: 42728.35,
    low: 42407.95,
    high: 42745.38,
    volume: 0.206,
    time: 1705586400000
  },
  {
    open: 42728.35,
    close: 42630.49,
    low: 42598.93,
    high: 42808.46,
    volume: 0.211,
    time: 1705590000000
  },
  {
    open: 42630.49,
    close: 41932.81,
    low: 41730.53,
    high: 42630.49,
    volume: 0.21,
    time: 1705593600000
  },
  {
    open: 41932.81,
    close: 41817.87,
    low: 41517.35,
    high: 42165.88,
    volume: 0.207,
    time: 1705597200000
  },
  {
    open: 41817.87,
    close: 41501.77,
    low: 41423.21,
    high: 41917.43,
    volume: 0.203,
    time: 1705600800000
  },
  {
    open: 41501.77,
    close: 41024.06,
    low: 40884.22,
    high: 41590.47,
    volume: 0.204,
    time: 1705604400000
  },
  {
    open: 41024.06,
    close: 40830.26,
    low: 40671.93,
    high: 41304.14,
    volume: 0.21,
    time: 1705608000000
  },
  {
    open: 40830.26,
    close: 41095.42,
    low: 40785.13,
    high: 41271.41,
    volume: 0.209,
    time: 1705611600000
  },
  {
    open: 41095.42,
    close: 41349.13,
    low: 41061.65,
    high: 41383.97,
    volume: 0.205,
    time: 1705615200000
  },
  {
    open: 41349.13,
    close: 41328.61,
    low: 41195.89,
    high: 41375.06,
    volume: 0.213,
    time: 1705618800000
  },
  {
    open: 41328.61,
    close: 41307.99,
    low: 41114.99,
    high: 41380.55,
    volume: 0.21300000000000002,
    time: 1705622400000
  },
  {
    open: 41307.99,
    close: 41268.34,
    low: 41126.05,
    high: 41340.06,
    volume: 0.2,
    time: 1705626000000
  },
  {
    open: 41268.34,
    close: 41118.92,
    low: 41068.01,
    high: 41306.11,
    volume: 0.192,
    time: 1705629600000
  },
  {
    open: 41118.92,
    close: 41068.7,
    low: 40763.2,
    high: 41151.38,
    volume: 0.194,
    time: 1705633200000
  },
  {
    open: 41068.7,
    close: 41107.97,
    low: 41041.14,
    high: 41226.07,
    volume: 0.193,
    time: 1705636800000
  },
  {
    open: 41107.97,
    close: 41211.85,
    low: 41045.89,
    high: 41254.15,
    volume: 0.2,
    time: 1705640400000
  },
  {
    open: 41211.85,
    close: 41514.49,
    low: 41196.28,
    high: 41529.59,
    volume: 0.196,
    time: 1705644000000
  },
  {
    open: 41514.49,
    close: 41293.67,
    low: 41269.58,
    high: 41586.68,
    volume: 0.193,
    time: 1705647600000
  },
  {
    open: 41293.67,
    close: 41301.34,
    low: 41146.46,
    high: 41377.92,
    volume: 0.201,
    time: 1705651200000
  },
  {
    open: 41301.34,
    close: 41368.91,
    low: 41285,
    high: 41466.47,
    volume: 0.063,
    time: 1705654800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705658400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705662000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705665600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705669200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705672800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705676400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705680000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705683600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705687200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705690800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705694400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705698000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705701600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705705200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705708800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705712400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705716000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705719600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705723200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705726800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705730400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705734000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705737600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705741200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705744800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705748400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705752000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705755600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705759200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705762800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705766400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705770000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705773600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705777200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705780800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705784400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705788000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705791600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705795200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705798800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705802400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705806000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705809600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705813200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705816800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705820400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705824000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705827600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705831200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705834800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705838400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705842000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705845600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705849200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705852800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705856400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705860000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705863600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705867200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705870800000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705874400000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705878000000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705881600000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705885200000
  },
  {
    open: 41368.91,
    close: 41368.91,
    low: 41368.91,
    high: 41368.91,
    volume: 0,
    time: 1705888800000
  },
  {
    open: 41368.91,
    close: 41187.06,
    low: 41070.84,
    high: 41368.91,
    volume: 0.20099999999999998,
    time: 1705892400000
  },
  {
    open: 41187.06,
    close: 40790.17,
    low: 40777.54,
    high: 41238.11,
    volume: 0.201,
    time: 1705896000000
  },
  {
    open: 40790.17,
    close: 41123.29,
    low: 40695.99,
    high: 41169.37,
    volume: 0.197,
    time: 1705899600000
  },
  {
    open: 41123.29,
    close: 41218.21,
    low: 41003.82,
    high: 41310.53,
    volume: 0.207,
    time: 1705903200000
  },
  {
    open: 41218.21,
    close: 41055.11,
    low: 40973.65,
    high: 41220.98,
    volume: 0.20199999999999999,
    time: 1705906800000
  },
  {
    open: 41055.11,
    close: 40964.97,
    low: 40751.41,
    high: 41055.11,
    volume: 0.20500000000000002,
    time: 1705910400000
  },
  {
    open: 40964.97,
    close: 40742.66,
    low: 40707.54,
    high: 41065.01,
    volume: 0.195,
    time: 1705914000000
  },
  {
    open: 40742.66,
    close: 40695.21,
    low: 40370.54,
    high: 40855.01,
    volume: 0.198,
    time: 1705917600000
  },
  {
    open: 40695.21,
    close: 40816.16,
    low: 40668.02,
    high: 40924.22,
    volume: 0.205,
    time: 1705921200000
  },
  {
    open: 40816.16,
    close: 40890.03,
    low: 40688.91,
    high: 40930.97,
    volume: 0.215,
    time: 1705924800000
  },
  {
    open: 40890.03,
    close: 40922.87,
    low: 40869.38,
    high: 41227.94,
    volume: 0.207,
    time: 1705928400000
  },
  {
    open: 40922.87,
    close: 40693.19,
    low: 40509.89,
    high: 40986.4,
    volume: 0.218,
    time: 1705932000000
  },
  {
    open: 40693.19,
    close: 40782.32,
    low: 40382.39,
    high: 40868.8,
    volume: 0.20800000000000002,
    time: 1705935600000
  },
  {
    open: 40782.32,
    close: 40521.73,
    low: 40403.51,
    high: 40864.36,
    volume: 0.21,
    time: 1705939200000
  },
  {
    open: 40521.73,
    close: 40724.87,
    low: 40460.79,
    high: 40816.75,
    volume: 0.208,
    time: 1705942800000
  },
  {
    open: 40724.87,
    close: 40310.21,
    low: 40138.71,
    high: 40786.89,
    volume: 0.2,
    time: 1705946400000
  },
  {
    open: 40310.21,
    close: 39823.16,
    low: 39441.92,
    high: 40539.08,
    volume: 0.20600000000000002,
    time: 1705950000000
  },
  {
    open: 39823.16,
    close: 40164.19,
    low: 39750.76,
    high: 40348.13,
    volume: 0.209,
    time: 1705953600000
  },
  {
    open: 40164.19,
    close: 39861.05,
    low: 39854.32,
    high: 40214.53,
    volume: 0.20199999999999999,
    time: 1705957200000
  },
  {
    open: 39861.05,
    close: 39811.93,
    low: 39513.88,
    high: 40074.17,
    volume: 0.196,
    time: 1705960800000
  },
  {
    open: 39811.93,
    close: 39562.09,
    low: 39474.42,
    high: 39965.87,
    volume: 0.214,
    time: 1705964400000
  },
  {
    open: 39562.09,
    close: 39859.26,
    low: 39510.12,
    high: 39901.92,
    volume: 0.20900000000000002,
    time: 1705968000000
  },
  {
    open: 39859.26,
    close: 39823.48,
    low: 39712.87,
    high: 39915.2,
    volume: 0.213,
    time: 1705971600000
  },
  {
    open: 39823.48,
    close: 40085.21,
    low: 39792.8,
    high: 40085.21,
    volume: 0.197,
    time: 1705975200000
  },
  {
    open: 40085.21,
    close: 40009.86,
    low: 39962.51,
    high: 40120.2,
    volume: 0.192,
    time: 1705978800000
  },
  {
    open: 40009.86,
    close: 40064.36,
    low: 39992.93,
    high: 40192.83,
    volume: 0.197,
    time: 1705982400000
  },
  {
    open: 40064.36,
    close: 40066.38,
    low: 40047.38,
    high: 40138.71,
    volume: 0.20400000000000001,
    time: 1705986000000
  },
  {
    open: 40066.38,
    close: 40038.82,
    low: 39972.43,
    high: 40136.73,
    volume: 0.20500000000000002,
    time: 1705989600000
  },
  {
    open: 40038.82,
    close: 39779.16,
    low: 39670.33,
    high: 40038.82,
    volume: 0.2,
    time: 1705993200000
  },
  {
    open: 39779.16,
    close: 39483.89,
    low: 39420.42,
    high: 39859.35,
    volume: 0.2,
    time: 1705996800000
  },
  {
    open: 39483.89,
    close: 39007.84,
    low: 38908.01,
    high: 39485.72,
    volume: 0.203,
    time: 1706000400000
  },
  {
    open: 39007.84,
    close: 38910.07,
    low: 38873.35,
    high: 39158.67,
    volume: 0.206,
    time: 1706004000000
  },
  {
    open: 38910.07,
    close: 38827.48,
    low: 38647.5,
    high: 39047.81,
    volume: 0.201,
    time: 1706007600000
  },
  {
    open: 38827.48,
    close: 39059.42,
    low: 38751.39,
    high: 39076.58,
    volume: 0.19999999999999998,
    time: 1706011200000
  },
  {
    open: 39059.42,
    close: 38881.39,
    low: 38855.57,
    high: 39070.24,
    volume: 0.207,
    time: 1706014800000
  },
  {
    open: 38881.39,
    close: 38778.12,
    low: 38540.91,
    high: 38885.97,
    volume: 0.206,
    time: 1706018400000
  },
  {
    open: 38778.12,
    close: 39202.67,
    low: 38778.12,
    high: 39411.16,
    volume: 0.20400000000000001,
    time: 1706022000000
  },
  {
    open: 39202.67,
    close: 39288.58,
    low: 38923.71,
    high: 39364.23,
    volume: 0.211,
    time: 1706025600000
  },
  {
    open: 39288.58,
    close: 39383.98,
    low: 39256.67,
    high: 39637.73,
    volume: 0.21,
    time: 1706029200000
  },
  {
    open: 39383.98,
    close: 39104.8,
    low: 39104.8,
    high: 39640.56,
    volume: 0.212,
    time: 1706032800000
  },
  {
    open: 39104.8,
    close: 39171.05,
    low: 38987.63,
    high: 39294.22,
    volume: 0.215,
    time: 1706036400000
  },
  {
    open: 39171.05,
    close: 39206.39,
    low: 39035.14,
    high: 39358.58,
    volume: 0.217,
    time: 1706040000000
  },
  {
    open: 39206.39,
    close: 39252.11,
    low: 39052.85,
    high: 39298.28,
    volume: 0.205,
    time: 1706043600000
  },
  {
    open: 39252.11,
    close: 39471.2,
    low: 39188.69,
    high: 39571.89,
    volume: 0.21,
    time: 1706047200000
  },
  {
    open: 39471.2,
    close: 39907.65,
    low: 39468.33,
    high: 39907.65,
    volume: 0.207,
    time: 1706050800000
  },
  {
    open: 39907.65,
    close: 40152.63,
    low: 39766.72,
    high: 40246.67,
    volume: 0.207,
    time: 1706054400000
  },
  {
    open: 40152.63,
    close: 39781.34,
    low: 39772.58,
    high: 40192.76,
    volume: 0.21100000000000002,
    time: 1706058000000
  },
  {
    open: 39781.34,
    close: 39772.1,
    low: 39708.77,
    high: 39901.46,
    volume: 0.191,
    time: 1706061600000
  },
  {
    open: 39772.1,
    close: 39605.85,
    low: 39560.4,
    high: 39799.81,
    volume: 0.198,
    time: 1706065200000
  },
  {
    open: 39605.85,
    close: 39646.42,
    low: 39542.77,
    high: 39758.29,
    volume: 0.202,
    time: 1706068800000
  },
  {
    open: 39646.42,
    close: 39809.45,
    low: 39646.42,
    high: 39833.71,
    volume: 0.195,
    time: 1706072400000
  },
  {
    open: 39809.45,
    close: 39885.65,
    low: 39648.66,
    high: 39906.88,
    volume: 0.20299999999999999,
    time: 1706076000000
  },
  {
    open: 39885.65,
    close: 39966.54,
    low: 39812.67,
    high: 40170.14,
    volume: 0.193,
    time: 1706079600000
  },
  {
    open: 39966.54,
    close: 39968.7,
    low: 39852.16,
    high: 40135.77,
    volume: 0.194,
    time: 1706083200000
  },
  {
    open: 39968.7,
    close: 40049.69,
    low: 39905.37,
    high: 40108.39,
    volume: 0.186,
    time: 1706086800000
  },
  {
    open: 40049.69,
    close: 40350.67,
    low: 39975.66,
    high: 40526.99,
    volume: 0.198,
    time: 1706090400000
  },
  {
    open: 40350.67,
    close: 40201.73,
    low: 40098.21,
    high: 40384.93,
    volume: 0.204,
    time: 1706094000000
  },
  {
    open: 40201.73,
    close: 40003.55,
    low: 39969.23,
    high: 40284.73,
    volume: 0.198,
    time: 1706097600000
  },
  {
    open: 40003.55,
    close: 40004.62,
    low: 39896.79,
    high: 40118.73,
    volume: 0.208,
    time: 1706101200000
  },
  {
    open: 40004.62,
    close: 39907.24,
    low: 39763.83,
    high: 40229.91,
    volume: 0.21,
    time: 1706104800000
  },
  {
    open: 39907.24,
    close: 40055.32,
    low: 39833.93,
    high: 40120.5,
    volume: 0.21,
    time: 1706108400000
  },
  {
    open: 40055.32,
    close: 40178.25,
    low: 39924.88,
    high: 40201.96,
    volume: 0.207,
    time: 1706112000000
  },
  {
    open: 40178.25,
    close: 40145.32,
    low: 40037.13,
    high: 40211.33,
    volume: 0.205,
    time: 1706115600000
  },
  {
    open: 40145.32,
    close: 39787.07,
    low: 39623.96,
    high: 40162.35,
    volume: 0.208,
    time: 1706119200000
  },
  {
    open: 39787.07,
    close: 39805.72,
    low: 39654.48,
    high: 39862.07,
    volume: 0.21,
    time: 1706122800000
  },
  {
    open: 39805.72,
    close: 39562.69,
    low: 39519.08,
    high: 39991.41,
    volume: 0.217,
    time: 1706126400000
  },
  {
    open: 39562.69,
    close: 39774.91,
    low: 39526.9,
    high: 39790.05,
    volume: 0.202,
    time: 1706130000000
  },
  {
    open: 39774.91,
    close: 39865.01,
    low: 39747.64,
    high: 39911.58,
    volume: 0.208,
    time: 1706133600000
  },
  {
    open: 39865.01,
    close: 40103.53,
    low: 39842.16,
    high: 40179.29,
    volume: 0.203,
    time: 1706137200000
  },
  {
    open: 40103.53,
    close: 40185.97,
    low: 40002.59,
    high: 40185.97,
    volume: 0.202,
    time: 1706140800000
  },
  {
    open: 40185.97,
    close: 39961.28,
    low: 39935.5,
    high: 40185.97,
    volume: 0.198,
    time: 1706144400000
  },
  {
    open: 39961.28,
    close: 39936.68,
    low: 39917.62,
    high: 40071.02,
    volume: 0.194,
    time: 1706148000000
  },
  {
    open: 39936.68,
    close: 39968.48,
    low: 39924.86,
    high: 40011.16,
    volume: 0.039,
    time: 1706151600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706155200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706158800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706162400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706166000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706169600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706173200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706176800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706180400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706184000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706187600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706191200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706194800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706198400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706202000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706205600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706209200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706212800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706216400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706220000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706223600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706227200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706230800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706234400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706238000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706241600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706245200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706248800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706252400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706256000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706259600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706263200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706266800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706270400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706274000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706277600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706281200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706284800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706288400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706292000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706295600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706299200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706302800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706306400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706310000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706313600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706317200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706320800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706324400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706328000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706331600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706335200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706338800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706342400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706346000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706349600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706353200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706356800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706360400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706364000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706367600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706371200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706374800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706378400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706382000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706385600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706389200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706392800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706396400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706400000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706403600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706407200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706410800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706414400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706418000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706421600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706425200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706428800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706432400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706436000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706439600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706443200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706446800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706450400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706454000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706457600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706461200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706464800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706468400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706472000000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706475600000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706479200000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706482800000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706486400000
  },
  {
    open: 39968.48,
    close: 39968.48,
    low: 39968.48,
    high: 39968.48,
    volume: 0,
    time: 1706490000000
  },
  {
    open: 39968.48,
    close: 42228.05,
    low: 39968.48,
    high: 42327.4,
    volume: 0.14400000000000002,
    time: 1706493600000
  },
  {
    open: 42228.05,
    close: 42331.97,
    low: 42222.05,
    high: 42515.92,
    volume: 0.182,
    time: 1706497200000
  },
  {
    open: 42331.97,
    close: 42231.22,
    low: 42186.82,
    high: 42360.24,
    volume: 0.197,
    time: 1706500800000
  },
  {
    open: 42231.22,
    close: 42150.08,
    low: 42120.94,
    high: 42287.34,
    volume: 0.185,
    time: 1706504400000
  },
  {
    open: 42150.08,
    close: 42173.71,
    low: 42128.55,
    high: 42252.64,
    volume: 0.199,
    time: 1706508000000
  },
  {
    open: 42173.71,
    close: 42066.22,
    low: 41999.03,
    high: 42254.45,
    volume: 0.198,
    time: 1706511600000
  },
  {
    open: 42066.22,
    close: 42110.71,
    low: 41953.12,
    high: 42165.08,
    volume: 0.201,
    time: 1706515200000
  },
  {
    open: 42110.71,
    close: 42291.21,
    low: 41943.44,
    high: 42303.41,
    volume: 0.192,
    time: 1706518800000
  },
  {
    open: 42291.21,
    close: 42301.79,
    low: 42201.73,
    high: 42384.91,
    volume: 0.189,
    time: 1706522400000
  },
  {
    open: 42301.79,
    close: 42253.44,
    low: 42191.11,
    high: 42359.23,
    volume: 0.203,
    time: 1706526000000
  },
  {
    open: 42253.44,
    close: 42122.19,
    low: 41983.93,
    high: 42388.01,
    volume: 0.208,
    time: 1706529600000
  },
  {
    open: 42122.19,
    close: 41993.77,
    low: 41927.24,
    high: 42134.9,
    volume: 0.212,
    time: 1706533200000
  },
  {
    open: 41993.77,
    close: 41967.04,
    low: 41813.55,
    high: 42114.68,
    volume: 0.20400000000000001,
    time: 1706536800000
  },
  {
    open: 41967.04,
    close: 42599.76,
    low: 41876.8,
    high: 42634.47,
    volume: 0.209,
    time: 1706540400000
  },
  {
    open: 42599.76,
    close: 43181.39,
    low: 42532.24,
    high: 43300.05,
    volume: 0.211,
    time: 1706544000000
  },
  {
    open: 43181.39,
    close: 42989.18,
    low: 42916.86,
    high: 43322.52,
    volume: 0.212,
    time: 1706547600000
  },
  {
    open: 42989.18,
    close: 43042.05,
    low: 42888.63,
    high: 43124.97,
    volume: 0.208,
    time: 1706551200000
  },
  {
    open: 43042.05,
    close: 42993.25,
    low: 42981.68,
    high: 43149.24,
    volume: 0.203,
    time: 1706554800000
  },
  {
    open: 42993.25,
    close: 43144.62,
    low: 42974.84,
    high: 43214.76,
    volume: 0.213,
    time: 1706558400000
  },
  {
    open: 43144.62,
    close: 43186.66,
    low: 43061.4,
    high: 43250.08,
    volume: 0.206,
    time: 1706562000000
  },
  {
    open: 43186.66,
    close: 43112.65,
    low: 43067.62,
    high: 43220.43,
    volume: 0.203,
    time: 1706565600000
  },
  {
    open: 43112.65,
    close: 43316.43,
    low: 43062.25,
    high: 43325.78,
    volume: 0.206,
    time: 1706569200000
  },
  {
    open: 43316.43,
    close: 43144.13,
    low: 43136.13,
    high: 43316.43,
    volume: 0.20900000000000002,
    time: 1706572800000
  },
  {
    open: 43144.13,
    close: 43460.66,
    low: 43144.13,
    high: 43567.42,
    volume: 0.20500000000000002,
    time: 1706576400000
  },
  {
    open: 43460.66,
    close: 43600.82,
    low: 43431.81,
    high: 43770.46,
    volume: 0.19599999999999998,
    time: 1706580000000
  },
  {
    open: 43600.82,
    close: 43528.41,
    low: 43471.67,
    high: 43691.8,
    volume: 0.16400000000000003,
    time: 1706583600000
  },
  {
    open: 43528.41,
    close: 43422.2,
    low: 43396.41,
    high: 43589.5,
    volume: 0.196,
    time: 1706587200000
  },
  {
    open: 43422.2,
    close: 43365.92,
    low: 43335.46,
    high: 43482.67,
    volume: 0.201,
    time: 1706590800000
  },
  {
    open: 43365.92,
    close: 43331.2,
    low: 43263.67,
    high: 43438.84,
    volume: 0.205,
    time: 1706594400000
  },
  {
    open: 43331.2,
    close: 43370.75,
    low: 43313.63,
    high: 43457.28,
    volume: 0.203,
    time: 1706598000000
  },
  {
    open: 43370.75,
    close: 43424.15,
    low: 43230.59,
    high: 43486.9,
    volume: 0.201,
    time: 1706601600000
  },
  {
    open: 43424.15,
    close: 43330.1,
    low: 43307.2,
    high: 43473.64,
    volume: 0.19,
    time: 1706605200000
  },
  {
    open: 43330.1,
    close: 43500.01,
    low: 43281.15,
    high: 43512.6,
    volume: 0.12,
    time: 1706608800000
  },
  {
    open: 43500.01,
    close: 43457.68,
    low: 43418.2,
    high: 43540.56,
    volume: 0.037,
    time: 1706612400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706616000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706619600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706623200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706626800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706630400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706634000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706637600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706641200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706644800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706648400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706652000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706655600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706659200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706662800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706666400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706670000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706673600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706677200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706680800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706684400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706688000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706691600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706695200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706698800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706702400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706706000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706709600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706713200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706716800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706720400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706724000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706727600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706731200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706734800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706738400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706742000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706745600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706749200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706752800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706756400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706760000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706763600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706767200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706770800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706774400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706778000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706781600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706785200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706788800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706792400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706796000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706799600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706803200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706806800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706810400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706814000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706817600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706821200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706824800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706828400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706832000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706835600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706839200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706842800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706846400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706850000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706853600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706857200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706860800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706864400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706868000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706871600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706875200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706878800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706882400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706886000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706889600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706893200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706896800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706900400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706904000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706907600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706911200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706914800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706918400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706922000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706925600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706929200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706932800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706936400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706940000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706943600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706947200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706950800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706954400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706958000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706961600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706965200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706968800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706972400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706976000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706979600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706983200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706986800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706990400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706994000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1706997600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707001200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707004800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707008400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707012000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707015600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707019200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707022800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707026400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707030000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707033600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707037200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707040800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707044400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707048000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707051600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707055200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707058800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707062400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707066000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707069600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707073200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707076800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707080400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707084000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707087600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707091200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707094800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707098400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707102000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707105600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707109200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707112800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707116400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707120000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707123600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707127200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707130800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707134400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707138000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707141600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707145200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707148800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707152400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707156000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707159600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707163200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707166800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707170400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707174000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707177600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707181200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707184800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707188400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707192000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707195600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707199200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707202800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707206400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707210000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707213600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707217200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707220800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707224400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707228000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707231600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707235200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707238800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707242400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707246000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707249600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707253200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707256800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707260400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707264000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707267600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707271200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707274800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707278400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707282000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707285600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707289200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707292800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707296400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707300000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707303600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707307200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707310800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707314400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707318000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707321600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707325200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707328800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707332400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707336000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707339600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707343200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707346800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707350400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707354000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707357600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707361200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707364800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707368400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707372000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707375600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707379200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707382800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707386400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707390000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707393600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707397200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707400800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707404400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707408000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707411600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707415200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707418800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707422400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707426000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707429600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707433200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707436800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707440400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707444000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707447600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707451200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707454800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707458400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707462000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707465600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707469200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707472800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707476400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707480000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707483600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707487200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707490800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707494400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707498000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707501600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707505200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707508800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707512400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707516000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707519600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707523200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707526800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707530400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707534000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707537600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707541200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707544800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707548400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707552000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707555600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707559200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707562800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707566400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707570000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707573600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707577200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707580800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707584400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707588000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707591600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707595200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707598800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707602400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707606000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707609600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707613200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707616800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707620400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707624000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707627600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707631200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707634800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707638400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707642000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707645600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707649200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707652800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707656400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707660000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707663600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707667200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707670800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707674400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707678000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707681600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707685200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707688800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707692400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707696000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707699600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707703200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707706800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707710400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707714000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707717600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707721200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707724800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707728400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707732000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707735600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707739200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707742800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707746400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707750000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707753600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707757200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707760800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707764400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707768000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707771600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707775200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707778800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707782400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707786000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707789600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707793200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707796800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707800400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707804000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707807600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707811200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707814800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707818400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707822000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707825600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707829200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707832800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707836400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707840000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707843600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707847200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707850800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707854400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707858000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707861600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707865200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707868800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707872400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707876000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707879600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707883200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707886800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707890400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707894000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707897600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707901200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707904800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707908400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707912000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707915600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707919200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707922800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707926400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707930000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707933600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707937200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707940800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707944400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707948000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707951600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707955200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707958800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707962400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707966000000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707969600000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707973200000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707976800000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707980400000
  },
  {
    open: 43457.68,
    close: 43457.68,
    low: 43457.68,
    high: 43457.68,
    volume: 0,
    time: 1707984000000
  },
  {
    open: 43457.68,
    close: 44090.6,
    low: 43457.68,
    high: 44090.6,
    volume: 0.001,
    time: 1707987600000
  },
  {
    open: 44090.6,
    close: 49981,
    low: 44090.6,
    high: 49981,
    volume: 0.202,
    time: 1707991200000
  },
  {
    open: 49981,
    close: 52317.16,
    low: 49981,
    high: 52472.66,
    volume: 0.21,
    time: 1707994800000
  },
  {
    open: 52317.16,
    close: 52303.91,
    low: 52063.83,
    high: 52502.1,
    volume: 0.211,
    time: 1707998400000
  },
  {
    open: 52303.91,
    close: 52252.53,
    low: 52108.64,
    high: 52373.91,
    volume: 0.212,
    time: 1708002000000
  },
  {
    open: 52252.53,
    close: 52627.3,
    low: 52094.68,
    high: 52804.8,
    volume: 0.214,
    time: 1708005600000
  },
  {
    open: 52627.3,
    close: 51910.73,
    low: 51734.22,
    high: 52654.19,
    volume: 0.20800000000000002,
    time: 1708009200000
  },
  {
    open: 51910.73,
    close: 52267.23,
    low: 51733.97,
    high: 52381.35,
    volume: 0.21,
    time: 1708012800000
  },
  {
    open: 52267.23,
    close: 51862.1,
    low: 51846.2,
    high: 52314.17,
    volume: 0.213,
    time: 1708016400000
  },
  {
    open: 51862.1,
    close: 51988.83,
    low: 51833.21,
    high: 52061.61,
    volume: 0.209,
    time: 1708020000000
  },
  {
    open: 51988.83,
    close: 51992.72,
    low: 51744.75,
    high: 52061.9,
    volume: 0.21,
    time: 1708023600000
  },
  {
    open: 51992.72,
    close: 51669.69,
    low: 51604.53,
    high: 52026.93,
    volume: 0.21000000000000002,
    time: 1708027200000
  },
  {
    open: 51669.69,
    close: 51326.69,
    low: 51302.44,
    high: 51808.91,
    volume: 0.207,
    time: 1708030800000
  },
  {
    open: 51326.69,
    close: 51646.3,
    low: 51307.12,
    high: 51816,
    volume: 0.213,
    time: 1708034400000
  },
  {
    open: 51646.3,
    close: 51855.4,
    low: 51635.64,
    high: 51989.04,
    volume: 0.208,
    time: 1708038000000
  },
  {
    open: 51855.4,
    close: 51922.19,
    low: 51787.09,
    high: 52022.53,
    volume: 0.212,
    time: 1708041600000
  },
  {
    open: 51922.19,
    close: 51977.78,
    low: 51871.24,
    high: 52119.89,
    volume: 0.215,
    time: 1708045200000
  },
  {
    open: 51977.78,
    close: 52144.38,
    low: 51829.17,
    high: 52211.2,
    volume: 0.20800000000000002,
    time: 1708048800000
  },
  {
    open: 52144.38,
    close: 52277.41,
    low: 52075.99,
    high: 52350.27,
    volume: 0.213,
    time: 1708052400000
  },
  {
    open: 52277.41,
    close: 52097.55,
    low: 52097.55,
    high: 52363.11,
    volume: 0.21,
    time: 1708056000000
  },
  {
    open: 52097.55,
    close: 52080.02,
    low: 52004.59,
    high: 52206.75,
    volume: 0.20700000000000002,
    time: 1708059600000
  },
  {
    open: 52080.02,
    close: 51884.63,
    low: 51832.44,
    high: 52186.22,
    volume: 0.213,
    time: 1708063200000
  },
  {
    open: 51884.63,
    close: 51733.78,
    low: 32198.7,
    high: 55040.1,
    volume: 0.6659,
    time: 1708066800000
  },
  {
    open: 51733.78,
    close: 51837.95,
    low: 51690.68,
    high: 52002.73,
    volume: 0.21200000000000002,
    time: 1708070400000
  },
  {
    open: 51837.95,
    close: 51817.32,
    low: 51586.09,
    high: 51971.99,
    volume: 0.206,
    time: 1708074000000
  },
  {
    open: 51817.32,
    close: 52324.61,
    low: 51765.42,
    high: 52324.61,
    volume: 0.20500000000000002,
    time: 1708077600000
  },
  {
    open: 52324.61,
    close: 52262.15,
    low: 52219.86,
    high: 52343.62,
    volume: 0.212,
    time: 1708081200000
  },
  {
    open: 52262.15,
    close: 52287.36,
    low: 52212.27,
    high: 52295.24,
    volume: 0.208,
    time: 1708084800000
  },
  {
    open: 52287.36,
    close: 52281.59,
    low: 52244.19,
    high: 52345.13,
    volume: 0.21,
    time: 1708088400000
  },
  {
    open: 52281.59,
    close: 52124.32,
    low: 51887.96,
    high: 52427.75,
    volume: 0.211,
    time: 1708092000000
  },
  {
    open: 52124.32,
    close: 52050.7,
    low: 51724.4,
    high: 52274.91,
    volume: 0.21,
    time: 1708095600000
  },
  {
    open: 52050.7,
    close: 52271.78,
    low: 51959.56,
    high: 52275.64,
    volume: 0.21,
    time: 1708099200000
  },
  {
    open: 52271.78,
    close: 52170.14,
    low: 51954.85,
    high: 52274.82,
    volume: 0.206,
    time: 1708102800000
  },
  {
    open: 52170.14,
    close: 52069.74,
    low: 51934.95,
    high: 52275.51,
    volume: 0.202,
    time: 1708106400000
  },
  {
    open: 52069.74,
    close: 51939.09,
    low: 51765.26,
    high: 52213.55,
    volume: 0.20400000000000001,
    time: 1708110000000
  },
  {
    open: 51939.09,
    close: 52005.56,
    low: 51824.59,
    high: 52253.32,
    volume: 0.209,
    time: 1708113600000
  },
  {
    open: 52005.56,
    close: 52098.08,
    low: 51864.27,
    high: 52194.11,
    volume: 0.2,
    time: 1708117200000
  },
  {
    open: 52098.08,
    close: 52081.88,
    low: 51994.94,
    high: 52267.65,
    volume: 0.2,
    time: 1708120800000
  },
  {
    open: 52081.88,
    close: 52228.41,
    low: 52065.99,
    high: 52275.73,
    volume: 0.211,
    time: 1708124400000
  },
  {
    open: 52228.41,
    close: 52107.87,
    low: 52107.87,
    high: 52274.95,
    volume: 0.207,
    time: 1708128000000
  },
  {
    open: 52107.87,
    close: 52075.33,
    low: 51972.84,
    high: 52275.5,
    volume: 0.2,
    time: 1708131600000
  },
  {
    open: 52075.33,
    close: 52244.59,
    low: 51989.57,
    high: 52275.46,
    volume: 0.20199999999999999,
    time: 1708135200000
  },
  {
    open: 52244.59,
    close: 52176.79,
    low: 52007.75,
    high: 52275.08,
    volume: 0.207,
    time: 1708138800000
  },
  {
    open: 52176.79,
    close: 52112.08,
    low: 52033.94,
    high: 52275.36,
    volume: 0.212,
    time: 1708142400000
  },
  {
    open: 52112.08,
    close: 52074.02,
    low: 51903.66,
    high: 52275.44,
    volume: 0.21,
    time: 1708146000000
  },
  {
    open: 52074.02,
    close: 52266.07,
    low: 51991.92,
    high: 52275.13,
    volume: 0.206,
    time: 1708149600000
  },
  {
    open: 52266.07,
    close: 52258.82,
    low: 52020.42,
    high: 52274.98,
    volume: 0.209,
    time: 1708153200000
  },
  {
    open: 52258.82,
    close: 51904.47,
    low: 51829.49,
    high: 52275.61,
    volume: 0.214,
    time: 1708156800000
  },
  {
    open: 51904.47,
    close: 51824.54,
    low: 51754.89,
    high: 52275.38,
    volume: 0.174,
    time: 1708160400000
  },
  {
    open: 51824.54,
    close: 51721.67,
    low: 51502.04,
    high: 51824.54,
    volume: 8.209999999999999,
    time: 1708164000000
  },
  {
    open: 51721.67,
    close: 51690.48,
    low: 51680.19,
    high: 51799.99,
    volume: 8.39,
    time: 1708167600000
  },
  {
    open: 51690.48,
    close: 51711.1,
    low: 51558.33,
    high: 51735.54,
    volume: 7.29,
    time: 1708171200000
  },
  {
    open: 51711.1,
    close: 51191.6,
    low: 51095.4,
    high: 51713.38,
    volume: 6.989999999999999,
    time: 1708174800000
  },
  {
    open: 51191.6,
    close: 50830.55,
    low: 50634.01,
    high: 51329.6,
    volume: 6.49,
    time: 1708178400000
  },
  {
    open: 50830.55,
    close: 50989.99,
    low: 50651.1,
    high: 51172.83,
    volume: 6.43,
    time: 1708182000000
  },
  {
    open: 50989.99,
    close: 50951.26,
    low: 50741.1,
    high: 51027.15,
    volume: 6.52,
    time: 1708185600000
  },
  {
    open: 50951.26,
    close: 51215.58,
    low: 50951.26,
    high: 51264.54,
    volume: 6.54,
    time: 1708189200000
  },
  {
    open: 51215.58,
    close: 51263.96,
    low: 51146.01,
    high: 51291.77,
    volume: 6.489999999999999,
    time: 1708192800000
  },
  {
    open: 51263.96,
    close: 51394.44,
    low: 51232.8,
    high: 51443.99,
    volume: 6.29,
    time: 1708196400000
  },
  {
    open: 51394.44,
    close: 51509.99,
    low: 51307.99,
    high: 51561.01,
    volume: 6.1899999999999995,
    time: 1708200000000
  },
  {
    open: 51509.99,
    close: 51803.85,
    low: 51444.43,
    high: 52007.55,
    volume: 5.7,
    time: 1708203600000
  },
  {
    open: 51803.85,
    close: 51710.51,
    low: 51590.01,
    high: 51864.67,
    volume: 5.49,
    time: 1708207200000
  },
  {
    open: 51710.51,
    close: 51642.65,
    low: 51592.06,
    high: 51735.72,
    volume: 5.4,
    time: 1708210800000
  },
  {
    open: 51642.65,
    close: 51676.95,
    low: 51545.48,
    high: 51761.73,
    volume: 5.28,
    time: 1708214400000
  },
  {
    open: 51676.95,
    close: 51520.87,
    low: 51482.13,
    high: 51798.99,
    volume: 5.25,
    time: 1708218000000
  },
  {
    open: 51520.87,
    close: 51277.99,
    low: 51181.1,
    high: 51554.38,
    volume: 5.41,
    time: 1708221600000
  },
  {
    open: 51277.99,
    close: 51479.99,
    low: 51261.99,
    high: 51479.99,
    volume: 5.4,
    time: 1708225200000
  },
  {
    open: 51479.99,
    close: 51564.12,
    low: 51477.73,
    high: 51639.99,
    volume: 5.12,
    time: 1708228800000
  },
  {
    open: 51564.12,
    close: 51656.88,
    low: 51541.24,
    high: 51688.87,
    volume: 5.11,
    time: 1708232400000
  },
  {
    open: 51656.88,
    close: 51503.46,
    low: 51405.52,
    high: 51700.01,
    volume: 5.05,
    time: 1708236000000
  },
  {
    open: 51503.46,
    close: 51671.1,
    low: 51451.81,
    high: 51746.23,
    volume: 4.87,
    time: 1708239600000
  },
  {
    open: 51671.1,
    close: 51790.01,
    low: 51644.01,
    high: 51795.04,
    volume: 4.71,
    time: 1708243200000
  },
  {
    open: 51790.01,
    close: 51976.81,
    low: 51790.01,
    high: 51985.99,
    volume: 4.659999999999999,
    time: 1708246800000
  },
  {
    open: 51976.81,
    close: 51830.84,
    low: 51794.01,
    high: 52061.23,
    volume: 4.68,
    time: 1708250400000
  },
  {
    open: 51830.84,
    close: 51832.01,
    low: 51691.95,
    high: 51834.2,
    volume: 4.63,
    time: 1708254000000
  },
  {
    open: 51832.01,
    close: 51554.07,
    low: 51535.02,
    high: 51865.2,
    volume: 4.49,
    time: 1708257600000
  },
  {
    open: 51554.07,
    close: 51628.44,
    low: 51541.1,
    high: 51687.99,
    volume: 4.4799999999999995,
    time: 1708261200000
  },
  {
    open: 51628.44,
    close: 51881.99,
    low: 51628.44,
    high: 51944.77,
    volume: 4.109999999999999,
    time: 1708264800000
  },
  {
    open: 51881.99,
    close: 51773.99,
    low: 51727.17,
    high: 51888.88,
    volume: 4.1000000000000005,
    time: 1708268400000
  },
  {
    open: 51773.99,
    close: 51835.57,
    low: 51668.26,
    high: 51879.76,
    volume: 4.12,
    time: 1708272000000
  },
  {
    open: 51835.57,
    close: 51837.94,
    low: 51786.82,
    high: 51977.69,
    volume: 3.99,
    time: 1708275600000
  },
  {
    open: 51837.94,
    close: 51793.96,
    low: 51649.1,
    high: 51837.94,
    volume: 4.0600000000000005,
    time: 1708279200000
  },
  {
    open: 51793.96,
    close: 51766.21,
    low: 51696.01,
    high: 51872.85,
    volume: 3.9699999999999998,
    time: 1708282800000
  },
  {
    open: 51766.21,
    close: 51776.01,
    low: 51589.99,
    high: 51782.87,
    volume: 3.95,
    time: 1708286400000
  },
  {
    open: 51776.01,
    close: 51861.1,
    low: 51754.95,
    high: 51922.57,
    volume: 3.88,
    time: 1708290000000
  },
  {
    open: 51861.1,
    close: 52239.8,
    low: 51833.07,
    high: 52374.81,
    volume: 3.29,
    time: 1708293600000
  },
  {
    open: 52239.8,
    close: 52131.99,
    low: 51983.02,
    high: 52349.99,
    volume: 3.25,
    time: 1708297200000
  },
  {
    open: 52131.99,
    close: 52183.44,
    low: 52103.88,
    high: 52375.21,
    volume: 3.0699999999999994,
    time: 1708300800000
  },
  {
    open: 52183.44,
    close: 52092.07,
    low: 51909.8,
    high: 52184.66,
    volume: 2.67,
    time: 1708304400000
  },
  {
    open: 52092.07,
    close: 52148.6,
    low: 52092.07,
    high: 52210.48,
    volume: 1.091,
    time: 1708308000000
  },
  {
    open: 52148.6,
    close: 52063.8,
    low: 51978.34,
    high: 52167.34,
    volume: 0.563,
    time: 1708311600000
  },
  {
    open: 52063.8,
    close: 52097.38,
    low: 52020.62,
    high: 52214.96,
    volume: 0.567,
    time: 1708315200000
  },
  {
    open: 52097.38,
    close: 52130.43,
    low: 51989.22,
    high: 52153.46,
    volume: 0.427,
    time: 1708318800000
  },
  {
    open: 52130.43,
    close: 52387.52,
    low: 52130.43,
    high: 52470.32,
    volume: 0.26,
    time: 1708322400000
  },
  {
    open: 52387.52,
    close: 52408.37,
    low: 52314.3,
    high: 52481.2,
    volume: 1.107,
    time: 1708326000000
  },
  {
    open: 52408.37,
    close: 52098.49,
    low: 52069.99,
    high: 52410.72,
    volume: 0.927,
    time: 1708329600000
  },
  {
    open: 52098.49,
    close: 52262.99,
    low: 52098.49,
    high: 52358.24,
    volume: 0.59,
    time: 1708333200000
  },
  {
    open: 52262.99,
    close: 52363.94,
    low: 52164.6,
    high: 52449.59,
    volume: 0.41900000000000004,
    time: 1708336800000
  },
  {
    open: 52363.94,
    close: 52334.39,
    low: 52284.93,
    high: 52416.22,
    volume: 0.28400000000000003,
    time: 1708340400000
  },
  {
    open: 52334.39,
    close: 52270.7,
    low: 52232.41,
    high: 52453.7,
    volume: 0.301,
    time: 1708344000000
  },
  {
    open: 52270.7,
    close: 52156.5,
    low: 52132.49,
    high: 52372.55,
    volume: 1.642,
    time: 1708347600000
  },
  {
    open: 52156.5,
    close: 52103.92,
    low: 52043.84,
    high: 52191.85,
    volume: 2.767,
    time: 1708351200000
  },
  {
    open: 52103.92,
    close: 52120.8,
    low: 52059.39,
    high: 52331.94,
    volume: 2.814,
    time: 1708354800000
  },
  {
    open: 52120.8,
    close: 51764.3,
    low: 51704.1,
    high: 52220.4,
    volume: 2.714,
    time: 1708358400000
  },
  {
    open: 51764.3,
    close: 52117.57,
    low: 51723.8,
    high: 52191.35,
    volume: 2.744,
    time: 1708362000000
  },
  {
    open: 52117.57,
    close: 51935.41,
    low: 51935.41,
    high: 52194.45,
    volume: 2.782,
    time: 1708365600000
  },
  {
    open: 51935.41,
    close: 51835.68,
    low: 51789.92,
    high: 51935.41,
    volume: 2.513,
    time: 1708369200000
  },
  {
    open: 51835.68,
    close: 51798.99,
    low: 51766.09,
    high: 51918.51,
    volume: 2.43,
    time: 1708372800000
  },
  {
    open: 51798.99,
    close: 51904.42,
    low: 51705.12,
    high: 51920.32,
    volume: 1.2,
    time: 1708376400000
  },
  {
    open: 51904.42,
    close: 51926.9,
    low: 51868.02,
    high: 52109.59,
    volume: 0.8200000000000001,
    time: 1708380000000
  },
  {
    open: 51926.9,
    close: 51774.74,
    low: 51711.1,
    high: 52012.1,
    volume: 0.825,
    time: 1708383600000
  },
  {
    open: 51774.74,
    close: 51645.04,
    low: 51500.41,
    high: 51828.6,
    volume: 0.818,
    time: 1708387200000
  },
  {
    open: 51645.04,
    close: 51631.1,
    low: 51325.01,
    high: 51707.16,
    volume: 0.7040000000000001,
    time: 1708390800000
  },
  {
    open: 51631.1,
    close: 51710.23,
    low: 51550.41,
    high: 51755.6,
    volume: 0.484,
    time: 1708394400000
  },
  {
    open: 51710.23,
    close: 51580.24,
    low: 51579.41,
    high: 51780.71,
    volume: 0.431,
    time: 1708398000000
  },
  {
    open: 51580.24,
    close: 51792,
    low: 51521.4,
    high: 51817.99,
    volume: 0.28600000000000003,
    time: 1708401600000
  },
  {
    open: 51792,
    close: 51905.65,
    low: 51755.28,
    high: 51983.62,
    volume: 0.281,
    time: 1708405200000
  },
  {
    open: 51905.65,
    close: 51948.46,
    low: 51905.65,
    high: 52039.5,
    volume: 0.28900000000000003,
    time: 1708408800000
  },
  {
    open: 51948.46,
    close: 51857.36,
    low: 51837.47,
    high: 51974.58,
    volume: 0.162,
    time: 1708412400000
  },
  {
    open: 51857.36,
    close: 51780,
    low: 51592.41,
    high: 51857.36,
    volume: 0.185,
    time: 1708416000000
  },
  {
    open: 51780,
    close: 51785.4,
    low: 51561.51,
    high: 51822.99,
    volume: 0.211,
    time: 1708419600000
  },
  {
    open: 51785.4,
    close: 52111.49,
    low: 51769.14,
    high: 52112.83,
    volume: 0.216,
    time: 1708423200000
  },
  {
    open: 52111.49,
    close: 52290.7,
    low: 52111.49,
    high: 52379.59,
    volume: 0.269,
    time: 1708426800000
  },
  {
    open: 52290.7,
    close: 52177.92,
    low: 52130.83,
    high: 52378.25,
    volume: 0.282,
    time: 1708430400000
  },
  {
    open: 52177.92,
    close: 52838.56,
    low: 52144.41,
    high: 52969.61,
    volume: 0.27,
    time: 1708434000000
  },
  {
    open: 52838.56,
    close: 52016,
    low: 51784.4,
    high: 52850.69,
    volume: 0.27,
    time: 1708437600000
  },
  {
    open: 52016,
    close: 51661.52,
    low: 51364.06,
    high: 52074.55,
    volume: 0.312,
    time: 1708441200000
  },
  {
    open: 51661.52,
    close: 51386.4,
    low: 51370.41,
    high: 51789.59,
    volume: 0.311,
    time: 1708444800000
  },
  {
    open: 51386.4,
    close: 51320.41,
    low: 50772.34,
    high: 51395.93,
    volume: 0.338,
    time: 1708448400000
  },
  {
    open: 51320.41,
    close: 51509.88,
    low: 51242.68,
    high: 51544.05,
    volume: 0.33999999999999997,
    time: 1708452000000
  },
  {
    open: 51509.88,
    close: 52101.1,
    low: 51431.91,
    high: 52117.59,
    volume: 0.33899999999999997,
    time: 1708455600000
  },
  {
    open: 52101.1,
    close: 52010.68,
    low: 51953.87,
    high: 52213.87,
    volume: 0.334,
    time: 1708459200000
  },
  {
    open: 52010.68,
    close: 52031.1,
    low: 51905.6,
    high: 52170.06,
    volume: 0.33499999999999996,
    time: 1708462800000
  },
  {
    open: 52031.1,
    close: 52214.05,
    low: 52006.41,
    high: 52304.76,
    volume: 0.341,
    time: 1708466400000
  },
  {
    open: 52214.05,
    close: 52258.83,
    low: 52168.39,
    high: 52499.59,
    volume: 0.334,
    time: 1708470000000
  },
  {
    open: 52258.83,
    close: 52181.1,
    low: 52123.11,
    high: 52366.8,
    volume: 0.308,
    time: 1708473600000
  },
  {
    open: 52181.1,
    close: 52093.69,
    low: 52050.42,
    high: 52290.69,
    volume: 0.27,
    time: 1708477200000
  },
  {
    open: 52093.69,
    close: 52061.97,
    low: 52041.51,
    high: 52159.59,
    volume: 0.251,
    time: 1708480800000
  },
  {
    open: 52061.97,
    close: 52001.99,
    low: 51988.92,
    high: 52094.77,
    volume: 0.24,
    time: 1708484400000
  },
  {
    open: 52001.99,
    close: 51942.9,
    low: 51818.42,
    high: 52009.99,
    volume: 0.243,
    time: 1708488000000
  },
  {
    open: 51942.9,
    close: 51837.95,
    low: 51818.87,
    high: 52106.3,
    volume: 0.252,
    time: 1708491600000
  },
  {
    open: 51837.95,
    close: 51741.06,
    low: 51674.71,
    high: 51889.57,
    volume: 0.267,
    time: 1708495200000
  },
  {
    open: 51741.06,
    close: 51582.48,
    low: 51526.35,
    high: 51780.04,
    volume: 0.246,
    time: 1708498800000
  },
  {
    open: 51582.48,
    close: 51646.97,
    low: 51543.61,
    high: 51771.12,
    volume: 0.336,
    time: 1708502400000
  },
  {
    open: 51646.97,
    close: 51081.5,
    low: 51001.34,
    high: 51689.59,
    volume: 0.23199999999999998,
    time: 1708506000000
  },
  {
    open: 51081.5,
    close: 51292.2,
    low: 50891.88,
    high: 51405.61,
    volume: 0.241,
    time: 1708509600000
  },
  {
    open: 51292.2,
    close: 50964.82,
    low: 50821.1,
    high: 51292.2,
    volume: 1.5,
    time: 1708513200000
  },
  {
    open: 50964.82,
    close: 51297.99,
    low: 50667.18,
    high: 51391.1,
    volume: 5.28,
    time: 1708516800000
  },
  {
    open: 51297.99,
    close: 51165.19,
    low: 50900.02,
    high: 51442.26,
    volume: 5.13,
    time: 1708520400000
  },
  {
    open: 51165.19,
    close: 50924.45,
    low: 50770.01,
    high: 51200.02,
    volume: 5.21,
    time: 1708524000000
  },
  {
    open: 50924.45,
    close: 51155.99,
    low: 50842.01,
    high: 51325.2,
    volume: 5.300000000000001,
    time: 1708527600000
  },
  {
    open: 51155.99,
    close: 51358.7,
    low: 51028.58,
    high: 51395.02,
    volume: 4.82,
    time: 1708531200000
  },
  {
    open: 51358.7,
    close: 50932.52,
    low: 50892.87,
    high: 51418.71,
    volume: 5.430000000000001,
    time: 1708534800000
  },
  {
    open: 50932.52,
    close: 50865.6,
    low: 50810.01,
    high: 51107.21,
    volume: 5.430000000000001,
    time: 1708538400000
  },
  {
    open: 50865.6,
    close: 51048.99,
    low: 50649.99,
    high: 51048.99,
    volume: 5.460000000000001,
    time: 1708542000000
  },
  {
    open: 51048.99,
    close: 50987.01,
    low: 50864.76,
    high: 51128.48,
    volume: 5.420000000000001,
    time: 1708545600000
  },
  {
    open: 50987.01,
    close: 51385.35,
    low: 50945.47,
    high: 51389.99,
    volume: 5.390000000000001,
    time: 1708549200000
  },
  {
    open: 51385.35,
    close: 51512.12,
    low: 51231.99,
    high: 51523.74,
    volume: 5.1,
    time: 1708552800000
  },
  {
    open: 51512.12,
    close: 51849.38,
    low: 51488.15,
    high: 51950.01,
    volume: 5.210000000000001,
    time: 1708556400000
  },
  {
    open: 51849.38,
    close: 51532.58,
    low: 51515.99,
    high: 51870.37,
    volume: 4.97,
    time: 1708560000000
  },
  {
    open: 51532.58,
    close: 51238.82,
    low: 51158.82,
    high: 51630.01,
    volume: 3.7,
    time: 1708563600000
  },
  {
    open: 51238.82,
    close: 51418.37,
    low: 51228.02,
    high: 51476.27,
    volume: 2.79,
    time: 1708567200000
  },
  {
    open: 51418.37,
    close: 51594.01,
    low: 51372.6,
    high: 51658.54,
    volume: 2.77,
    time: 1708570800000
  },
  {
    open: 51594.01,
    close: 51439.55,
    low: 51405.49,
    high: 51640.01,
    volume: 2.06,
    time: 1708574400000
  },
  {
    open: 51439.55,
    close: 51520.47,
    low: 51431.51,
    high: 51684.51,
    volume: 1.35,
    time: 1708578000000
  },
  {
    open: 51520.47,
    close: 51778.01,
    low: 51520.47,
    high: 51809.6,
    volume: 1.68,
    time: 1708581600000
  },
  {
    open: 51778.01,
    close: 51802.01,
    low: 51670.7,
    high: 51910.32,
    volume: 1.29,
    time: 1708585200000
  },
  {
    open: 51802.01,
    close: 51894.1,
    low: 51676.09,
    high: 51933.2,
    volume: 1.7799999999999998,
    time: 1708588800000
  },
  {
    open: 51894.1,
    close: 51945.32,
    low: 51701.63,
    high: 52033.2,
    volume: 7.67,
    time: 1708592400000
  },
  {
    open: 51945.32,
    close: 51694.08,
    low: 51594.01,
    high: 51945.32,
    volume: 17.05,
    time: 1708596000000
  },
  {
    open: 51694.08,
    close: 51621.1,
    low: 51539.48,
    high: 51694.08,
    volume: 3.21,
    time: 1708599600000
  },
  {
    open: 51621.1,
    close: 51521.11,
    low: 51424.29,
    high: 51733.05,
    volume: 4.1899999999999995,
    time: 1708603200000
  },
  {
    open: 51521.11,
    close: 51166.39,
    low: 50940.79,
    high: 51535.52,
    volume: 4.09,
    time: 1708606800000
  },
  {
    open: 51166.39,
    close: 51374.01,
    low: 51061.21,
    high: 51417.99,
    volume: 3.96,
    time: 1708610400000
  },
  {
    open: 51374.01,
    close: 51602.41,
    low: 51294.9,
    high: 51661.1,
    volume: 3.85,
    time: 1708614000000
  },
  {
    open: 51602.41,
    close: 51479.1,
    low: 51400.01,
    high: 51750.01,
    volume: 4.04,
    time: 1708617600000
  },
  {
    open: 51479.1,
    close: 51683.38,
    low: 51461.51,
    high: 51731.1,
    volume: 4.01,
    time: 1708621200000
  },
  {
    open: 51683.38,
    close: 51718.82,
    low: 51504.02,
    high: 51720.87,
    volume: 4.109999999999999,
    time: 1708624800000
  },
  {
    open: 51718.82,
    close: 51510,
    low: 51467.51,
    high: 51718.82,
    volume: 4.15,
    time: 1708628400000
  },
  {
    open: 51510,
    close: 51961.09,
    low: 51465.18,
    high: 52019.24,
    volume: 4.140000000000001,
    time: 1708632000000
  },
  {
    open: 51961.09,
    close: 51656.97,
    low: 51545.27,
    high: 51994.16,
    volume: 4.03,
    time: 1708635600000
  },
  {
    open: 51656.97,
    close: 51462.26,
    low: 51198.24,
    high: 51717.3,
    volume: 4.1,
    time: 1708639200000
  },
  {
    open: 51462.26,
    close: 51288.42,
    low: 51230.01,
    high: 51478.01,
    volume: 4.11,
    time: 1708642800000
  },
  {
    open: 51288.42,
    close: 51460.01,
    low: 51263.54,
    high: 51483.09,
    volume: 4.07,
    time: 1708646400000
  },
  {
    open: 51460.01,
    close: 51311.11,
    low: 51311.11,
    high: 51546.01,
    volume: 3.29,
    time: 1708650000000
  },
  {
    open: 51311.11,
    close: 51097.28,
    low: 51060.02,
    high: 51420,
    volume: 2.37,
    time: 1708653600000
  },
  {
    open: 51097.28,
    close: 51100,
    low: 50977.86,
    high: 51149.65,
    volume: 2.23,
    time: 1708657200000
  },
  {
    open: 51100,
    close: 51219.6,
    low: 51003.01,
    high: 51318.2,
    volume: 4.58,
    time: 1708660800000
  },
  {
    open: 51219.6,
    close: 51236.01,
    low: 51116.11,
    high: 51239.4,
    volume: 8.059999999999999,
    time: 1708664400000
  },
  {
    open: 51236.01,
    close: 51301.11,
    low: 51211.11,
    high: 51313.49,
    volume: 8.209999999999999,
    time: 1708668000000
  },
  {
    open: 51301.11,
    close: 50915.52,
    low: 50915.52,
    high: 51301.11,
    volume: 4.45,
    time: 1708671600000
  },
  {
    open: 50915.52,
    close: 50958.55,
    low: 50832.01,
    high: 51158.69,
    volume: 7.17,
    time: 1708675200000
  },
  {
    open: 50958.55,
    close: 51009.38,
    low: 50871.11,
    high: 51060.52,
    volume: 8.26,
    time: 1708678800000
  },
  {
    open: 51009.38,
    close: 51171.1,
    low: 50965.82,
    high: 51171.94,
    volume: 9.440000000000001,
    time: 1708682400000
  },
  {
    open: 51171.1,
    close: 51218.95,
    low: 51141.21,
    high: 51256.41,
    volume: 9.489999999999998,
    time: 1708686000000
  },
  {
    open: 51218.95,
    close: 50994.01,
    low: 50933.86,
    high: 51225.25,
    volume: 10.67,
    time: 1708689600000
  },
  {
    open: 50994.01,
    close: 51058.21,
    low: 50925.03,
    high: 51094.94,
    volume: 10.18,
    time: 1708693200000
  },
  {
    open: 51058.21,
    close: 51126,
    low: 50800.82,
    high: 51319.9,
    volume: 9.61,
    time: 1708696800000
  },
  {
    open: 51126,
    close: 50911.3,
    low: 50838.02,
    high: 51126,
    volume: 10.610000000000001,
    time: 1708700400000
  },
  {
    open: 50911.3,
    close: 51053.08,
    low: 50631.05,
    high: 51112.01,
    volume: 10.33,
    time: 1708704000000
  },
  {
    open: 51053.08,
    close: 51146.27,
    low: 50974.01,
    high: 51172.91,
    volume: 10.21,
    time: 1708707600000
  },
  {
    open: 51146.27,
    close: 51103.76,
    low: 51000.01,
    high: 51193.69,
    volume: 10.78,
    time: 1708711200000
  },
  {
    open: 51103.76,
    close: 51099.09,
    low: 51032.89,
    high: 51195.18,
    volume: 10.780000000000001,
    time: 1708714800000
  },
  {
    open: 51099.09,
    close: 51081.11,
    low: 50961.11,
    high: 51162.04,
    volume: 10.95,
    time: 1708718400000
  },
  {
    open: 51081.11,
    close: 50986.13,
    low: 50986.13,
    high: 51094.64,
    volume: 10.57,
    time: 1708722000000
  },
  {
    open: 50986.13,
    close: 50788.01,
    low: 50750.01,
    high: 51025.31,
    volume: 10.919999999999998,
    time: 1708725600000
  },
  {
    open: 50788.01,
    close: 50744.15,
    low: 50528.47,
    high: 50912.01,
    volume: 10.79,
    time: 1708729200000
  },
  {
    open: 50744.15,
    close: 50889.2,
    low: 50694.72,
    high: 50907.14,
    volume: 10.749999999999998,
    time: 1708732800000
  },
  {
    open: 50889.2,
    close: 50590.01,
    low: 50590.01,
    high: 50891.1,
    volume: 10.66,
    time: 1708736400000
  },
  {
    open: 50590.01,
    close: 50802.8,
    low: 50585.01,
    high: 50803.96,
    volume: 8.36,
    time: 1708740000000
  },
  {
    open: 50802.8,
    close: 50849.74,
    low: 50640.44,
    high: 50862.64,
    volume: 3.59,
    time: 1708743600000
  },
  {
    open: 50849.74,
    close: 50953.91,
    low: 50821.91,
    high: 51012.68,
    volume: 3.59,
    time: 1708747200000
  },
  {
    open: 50953.91,
    close: 51064.29,
    low: 50895.54,
    high: 51076.65,
    volume: 3.6,
    time: 1708750800000
  },
  {
    open: 51064.29,
    close: 50969.76,
    low: 50876.87,
    high: 51158.24,
    volume: 3.6,
    time: 1708754400000
  },
  {
    open: 50969.76,
    close: 51065.68,
    low: 50861.67,
    high: 51098.14,
    volume: 3.6,
    time: 1708758000000
  },
  {
    open: 51065.68,
    close: 51023.44,
    low: 50930.61,
    high: 51125.1,
    volume: 3.4800000000000004,
    time: 1708761600000
  },
  {
    open: 51023.44,
    close: 51091.83,
    low: 50940.68,
    high: 51140.63,
    volume: 11.16,
    time: 1708765200000
  },
  {
    open: 51091.83,
    close: 51082.94,
    low: 50756.37,
    high: 51478.79,
    volume: 11.399999999999999,
    time: 1708768800000
  },
  {
    open: 51082.94,
    close: 51115.81,
    low: 50953.11,
    high: 51177.3,
    volume: 6.170000000000001,
    time: 1708772400000
  }
]
