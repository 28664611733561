import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { ArrowDownIcon, Indicator, TickSuccess } from '../../assets/icon'
import '../../style/components/TopBarTradingView.scss'
import { CHART_TYPE, intervarMasterArr } from '../../utils/chart'

export interface IntervalObj {
  key: string
  value: string
}

interface Props {
  [key: string]: any
  containerId?: any
  isFullScreen: boolean
}

const defaultIntervalList = ['Time', '15m', '1h', '4h', '1D', '1W']

interface IItemNavBar {
  content: React.ReactNode
  active?: boolean
  onClick?: () => void
}

const ItemNavBar: React.FC<IItemNavBar> = ({ content = '', active = false, onClick }) => {
  return (
    <div className={`item-navbar ${active ? 'item-active' : ''}`} onClick={onClick}>
      {content}
    </div>
  )
}

const NavbarTradingView: React.FC<Props> = props => {
  const [isShowGroupInterval, setIsShowGroupInterval] = useState<boolean>(false)
  const [isShowChartType, setIsShowChartType] = useState<boolean>(false)
  const [chartTypeObj, setChartTypeObj] = useState<any>(CHART_TYPE[1])
  const [isEditBtn, setIsEditBtn] = useState<boolean>(true)
  const [intervalList, setIntervalList] = useState<Array<IntervalObj>>([])
  const [currentInterval, setCurrentInterval] = useState({
    key: '1h',
    value: '60',
    status: false,
    valueCompare: 60
  })

  const [isShowTooltip, setIsShowTooltip] = useState(false)

  const [isShowCurrentInterval, setIsShowCurrentInterval] = useState(false)
  useEffect(() => {
    intervarMasterArr.map(row => {
      row.row.map(item => {
        if (defaultIntervalList.includes(item.key)) {
          item.status = true
          setIntervalList(intervalArr => {
            if (intervalArr.find(inter => inter.key === item.key)) return intervalArr
            else return [...intervalArr, item]
          })
        }
      })
    })
  }, [props?.symbol])
  const useComponentVisible = (initialIsVisible: any) => {
    const [isCptVisible, setIsCptVisible] = useState(initialIsVisible)
    const ref = useRef<HTMLDivElement>(null)
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsCptVisible(false)
        if (isEditBtn) {
          setIsShowGroupInterval(false)
        }
        setIsShowChartType(false)
      }
    }

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true)
      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    })

    return { ref, isCptVisible, setIsCptVisible }
  }
  const { ref } = useComponentVisible(true)
  const setInterval = (param: any) => {
    setCurrentInterval(param)
    setIsShowGroupInterval(false)
    props.onSelectInterval(param.value)
  }
  const showOrHideGroupItv = () => {
    setIsShowGroupInterval(!isShowGroupInterval)
  }

  const openIndicatorPopup = () => {
    props.openIndicatorPopup()
  }

  const showOrHideChartType = () => {
    setIsShowChartType(!isShowChartType)
  }

  const isEditOrSave = () => {
    setIsEditBtn(!isEditBtn)
    if (!isEditBtn) {
      setIsShowGroupInterval(!isShowGroupInterval)
    }
  }

  const setChartType = (chartType: number) => {
    props.setChartType(chartType)
    setIsShowChartType(!setIsShowChartType)
  }

  const setIntervalToList = (intervalObj: any) => {
    intervarMasterArr.map(row => {
      row.row.map(item => {
        if (item.key === intervalObj.key) {
          item.status = !item.status
          if (item.status) {
            setIntervalList(intervalArr => _.sortBy([...intervalArr, item], 'valueCompare'))
          } else {
            setIntervalList(intervalList.filter(item => item.key !== intervalObj.key))
          }
        }
      })
    })
  }

  const handleShowCurrentResolution = () => {
    return (
      intervalList
        .slice(0, 7)
        .map(interval => interval.key)
        .includes(currentInterval.key) ||
      !isShowCurrentInterval ||
      currentInterval.value === intervarMasterArr[0].row[0].value
    )
  }

  const onMouseEnter = () => setIsShowTooltip(true)
  const onMouseLeave = () => setIsShowTooltip(false)

  return (
    <div>
      <div className={'navbar-tradingview'}>
        <div className={'navbar-tradingview__left'}>
          {intervalList.slice(0, 7).map((interval, idx) => {
            return (
              <ItemNavBar
                content={interval.key}
                onClick={() => {
                  setInterval(interval)
                  setIsShowCurrentInterval(() => false)
                }}
                key={idx}
                active={interval.value.toLowerCase() === currentInterval?.value?.toLowerCase()}
              />
            )
          })}
          <div className="current-interval">{handleShowCurrentResolution() ? '' : currentInterval.key}</div>

          <div className={'parent-position'}>
            <ItemNavBar content={<ArrowDownIcon fill="#A0A3BD" />} onClick={showOrHideGroupItv} />
            <div className={'group-interval-parent'}>
              {isShowGroupInterval ? (
                <div className={'group-interval'} ref={ref}>
                  <div className={'group-interval-header'}>
                    <div>Select Interval</div>
                    <div onClick={isEditOrSave} className={!isEditBtn ? 'btn-edit' : ''}>
                      {isEditBtn ? 'Edit' : 'Save'}
                    </div>
                  </div>
                  {intervarMasterArr.map((row, idx) => {
                    return (
                      <div className={`${'group-interval-line'} ${'group-interval-line-config'}`} key={idx}>
                        {row.row.map(it => {
                          return (
                            <div
                              key={it.key}
                              className={'interval-button'}
                              onClick={() => {
                                setIsShowCurrentInterval(() => true)
                                !isEditBtn ? setIntervalToList(it) : setInterval(it)
                              }}
                            >
                              <div className={it.status ? 'interval-btn-active' : ''}>{it.key}</div>
                              {!isEditBtn && it.status ? <TickSuccess className="interval-img" /> : null}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              ) : null}
            </div>
          </div>

          <div className="topbar-indicator" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <ItemNavBar content={<Indicator />} onClick={openIndicatorPopup} />
            {isShowTooltip && <div className="indicator-tooltip">Technical Indicator</div>}
          </div>

          <div className={'parent-position'}>
            <ItemNavBar content={<>{<chartTypeObj.src_type />}</>} onClick={showOrHideChartType} />
            <div className={'chart-stype-popup'}>
              {isShowChartType ? (
                <div className={'chart-type-parent'} ref={ref}>
                  {CHART_TYPE.map(item => {
                    return (
                      <div
                        onClick={() => {
                          setChartType(item.type_number)
                          setChartTypeObj(item)
                        }}
                        key={item.type_number}
                        className={'chart-type-child'}
                      >
                        <>
                          {<item.src_type />}
                          <div>{item.type_name}</div>
                        </>
                      </div>
                    )
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavbarTradingView
