import { Trade, TradeType } from '@blast/v2-sdk'
import React, { useContext, useMemo } from 'react'
import { ArrowDown, AlertTriangle } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { TYPE } from '../../theme'
import { ButtonPrimary } from '../Button'
import { isAddress, shortenAddress } from '../../utils'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges
}: {
  trade: Trade
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    trade,
    allowedSlippage
  ])
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const theme = useContext(ThemeContext)

  return (
    <AutoColumn gap={'24px'}>
      <AutoColumn gap={'4px'}>
        <RowFixed gap={'0px'}>
          <Text fontWeight={400} fontSize={'16px'} lineHeight={'24px'} color={'#67778E'}>
            You pay
          </Text>
        </RowFixed>

        <RowFixed gap={'0px'} style={{ width: 'unset', justifyContent: 'space-between' }}>
          <TruncatedText
            fontSize={'28px'}
            fontWeight={600}
            lineHeight={'42px'}
            color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.primary1 : ''}
          >
            {trade.inputAmount.toSignificant(6)} {trade.inputAmount.currency.symbol}
          </TruncatedText>

          <CurrencyLogo currency={trade.inputAmount.currency} size={'40px'} style={{ marginLeft: '10px' }} />
        </RowFixed>
      </AutoColumn>

      <AutoColumn gap={'4px'}>
        <Text fontWeight={400} fontSize={'16px'} lineHeight={'24px'} color={'#67778E'}>
          You receive
        </Text>

        <RowFixed gap={'0px'} style={{ width: 'unset', justifyContent: 'space-between' }}>
          <TruncatedText
            fontSize={'28px'}
            fontWeight={600}
            lineHeight={'42px'}
            color={
              priceImpactSeverity > 2
                ? theme.red1
                : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
                ? theme.primary1
                : ''
            }
          >
            {trade.outputAmount.toSignificant(6)} {trade.outputAmount.currency.symbol}
          </TruncatedText>

          <CurrencyLogo currency={trade.outputAmount.currency} size={'40px'} style={{ marginLeft: '10px' }} />
        </RowFixed>
      </AutoColumn>

      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} />
              <TYPE.main color={theme.primary1}> Price Updated</TYPE.main>
            </RowFixed>
            <ButtonPrimary
              style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px' }}
              onClick={onAcceptChanges}
            >
              Accept
            </ButtonPrimary>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}

      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <TYPE.main>
            Output will be sent to{' '}
            <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
          </TYPE.main>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
