/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { createContext, useCallback, useContext, useRef, useState } from 'react'
import { useEffectUnsafe } from '../../hooks/useEffectUnsafe'

const io = require('socket.io-client')
const PARAMS = {
  autoConnect: true,
  transports: ['websocket', 'polling'],
  reconnection: true
}

// TODO: change base url
const webSocketBaseUrl = process.env.REACT_APP_WEBSOCKET_API_URL || 'https://dev-amm-socket.lagom.exchange'

const socketPublic = io(webSocketBaseUrl, PARAMS)

export enum ESocketEvent {
  TICKERS = 'TickerUpdated',
  SUBSCRIBE = 'subscribe',
  ORDER_BOOK_UPDATED = 'OrderBookUpdated',
  UN_SUBSCRIBE = 'unsubscribe',
  TRADE_CREATED = 'TradeCreated',
  MY_TRADE_CREATED = 'MyTradeCreated',
  ORDER_UPDATED = 'OrderUpdated',
  ORDER_CREATED = 'OrderCreated',
  ACCOUNT_UPDATED = 'AccountUpdated'
}

type UnregisterFunc = () => void
type CallbackFunc = (...args: any[]) => any

interface SocketIOInterface {
  socketPublic: any
  socketPrivate: any
  statusPublic: ConnectionStatus
  errorPublic?: any
  statusPrivate: ConnectionStatus
  errorPrivate?: any
  publicListener: (forEvent: ESocketEvent, cb: CallbackFunc) => UnregisterFunc
  offPublicListener: (forEvent: ESocketEvent) => void
  publicEmit: (forEvent: ESocketEvent, payload: any) => void
}

const IoContext = createContext<SocketIOInterface>({
  socketPublic,
  socketPrivate: null,
  statusPublic: null,
  errorPublic: undefined,
  statusPrivate: null,
  errorPrivate: undefined,
  publicListener: () => () => {},
  offPublicListener: () => {},
  publicEmit: () => {}
})

export const useSocket = () => useContext(IoContext)

export type ConnectionStatus = 'connecting' | 'connected' | 'disconnected' | null

export const SocketProvider: React.FC<{ children: any }> = ({ children }) => {
  const [statusPublic, setStatusPublic] = useState<ConnectionStatus>(null)
  const [errorPublic, setErrorPublic] = useState<any>()

  const [statusPrivate, setStatusPrivate] = useState<ConnectionStatus>(null)
  const [errorPrivate, setErrorPrivate] = useState<any>()

  const socketPrivate = useRef<any>()

  const publicListener = useCallback((forEvent: ESocketEvent, callback: any) => {
    socketPublic.on(forEvent, callback)
    return () => {
      socketPublic.off(forEvent)
    }
  }, [])

  const offPublicListener = useCallback((forEvent: ESocketEvent) => {
    socketPublic.off(forEvent)
  }, [])

  const publicEmit = useCallback((forEvent: ESocketEvent, payload: any) => {
    socketPublic.emit(forEvent, payload)
  }, [])

  useEffectUnsafe(() => {
    socketPublic
      .connect()
      .on('connect_error', (errorPublic: any) => {
        setErrorPublic(errorPublic)
      })
      .on('disconnect', () => {
        setStatusPublic('disconnected')
        socketPublic.connect()
      })
      .on('connect', () => {
        setStatusPublic('connected')
      })
    return () => {
      socketPublic.disconnect()
    }
  }, [])

  useEffectUnsafe(() => {
    if (!socketPrivate?.current) return
    socketPrivate.current
      .connect()
      .on('connect_error', (errorPrivate: any) => {
        setErrorPrivate(errorPrivate)
      })
      .on('disconnect', () => {
        setStatusPrivate('disconnected')
      })
      .on('connect', () => {
        setStatusPrivate('connected')
      })
    return () => {
      if (!socketPrivate?.current) return
      socketPrivate.current.disconnect()
    }
  }, [])

  return (
    <IoContext.Provider
      value={{
        socketPublic,
        socketPrivate: socketPrivate.current,
        errorPublic,
        statusPublic,
        errorPrivate,
        statusPrivate,
        publicListener,
        offPublicListener,
        publicEmit
      }}
    >
      {children}
    </IoContext.Provider>
  )
}

export default useSocket
