import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 480px;
  width: 100%;
  border-radius: 12px;
  border: 1.353px solid #3b4254;
  background: #292f39;
  padding: 24px;
  backdrop-filter: blur(7.5px);
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
