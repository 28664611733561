import { ChainId, JSBI, Percent, Token, WETH, FACTORY_ADDRESS, INIT_CODE_HASH } from '@blast/v2-sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { NETWORK_CHAIN_ID, injected, walletlink } from '../connectors'
import { WrappedTokenInfo } from '../state/lists/hooks'

export const ROUTER_ADDRESSES = {
  [ChainId.BLAST_SEPOLIA]: '0xcDf9136c2AB0DA6e556F28152A80a09e327fF9Cf',
  [ChainId.BLAST_MAINNET]: '0xF1eCBC9B62dBFaAa1c34a2e3757DacFb6D99Ae07'
}

export const ROUTER_ADDRESS = ROUTER_ADDRESSES[NETWORK_CHAIN_ID as ChainId]
console.log(
  'NETWORK_CHAIN_ID',
  NETWORK_CHAIN_ID,
  'ROUTER_ADDRESS',
  ROUTER_ADDRESS,
  'FACTORY_ADDRESS',
  FACTORY_ADDRESS[NETWORK_CHAIN_ID as ChainId],
  'INIT_CODE_HASH',
  INIT_CODE_HASH[NETWORK_CHAIN_ID as ChainId]
)

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// export const DAI = new Token(ChainId.SEPOLIA, '0x7AF17A48a6336F7dc1beF9D485139f7B6f4FB5C8', 18, 'DAI', 'Dai Stablecoin')
// export const USDC = new Token(ChainId.SEPOLIA, '0x6f14C02Fc1F78322cFd7d707aB90f18baD3B54f5', 6, 'USDC', 'USD//C')
// export const USDT = new Token(ChainId.SEPOLIA, '0x7169d38820dfd117c3fa1f22a697dba58d90ba06', 6, 'USDT', 'Tether USD')

export const DEFAULT_OUTPUT_CURRENCY_ID: {
  readonly [chainId in ChainId]: string
} = {
  [ChainId.BLAST_SEPOLIA]: '0x4200000000000000000000000000000000000022',
  [ChainId.BLAST_MAINNET]: ''
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.BLAST_SEPOLIA]: [WETH[ChainId.BLAST_SEPOLIA]],
  [ChainId.BLAST_MAINNET]: [WETH[ChainId.BLAST_MAINNET]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.BLAST_SEPOLIA]: [
    // ...WETH_ONLY[ChainId.BLAST_SEPOLIA],
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_SEPOLIA,
        address: '0x4200000000000000000000000000000000000022',
        decimals: 18,
        symbol: 'USDB',
        name: 'Rebasing USD',
        logoURI: 'https://assets.coingecko.com/coins/images/24341/large/usdb.png?1696523525'
      },
      []
    ),
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_SEPOLIA,
        address: '0x4200000000000000000000000000000000000023',
        decimals: 18,
        symbol: 'WETH',
        name: 'Wrapped Ether',
        logoURI:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
      },
      []
    )
    //  DAI, USDC, USDT
  ]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  // ...WETH_ONLY,
  [ChainId.BLAST_SEPOLIA]: [
    // ...WETH_ONLY[ChainId.BLAST_SEPOLIA],
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_SEPOLIA,
        address: '0x4200000000000000000000000000000000000022',
        decimals: 18,
        symbol: 'USDB',
        name: 'Rebasing USD',
        logoURI: 'https://assets.coingecko.com/coins/images/24341/large/usdb.png?1696523525'
      },
      []
    ),
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_SEPOLIA,
        address: '0x4200000000000000000000000000000000000023',
        decimals: 18,
        symbol: 'WETH',
        name: 'Wrapped Ether',
        logoURI:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
      },
      []
    )
    // DAI, USDC, USDT,
  ],
  [ChainId.BLAST_MAINNET]: []
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.BLAST_SEPOLIA]: [
    // ...WETH_ONLY[ChainId.BLAST_SEPOLIA],
    //  DAI, USDC, USDT,
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_SEPOLIA,
        address: '0x4200000000000000000000000000000000000022',
        decimals: 18,
        symbol: 'USDB',
        name: 'Rebasing USD',
        logoURI: 'https://assets.coingecko.com/coins/images/24341/large/usdb.png?1696523525'
      },
      []
    ),
    new WrappedTokenInfo(
      {
        chainId: ChainId.BLAST_SEPOLIA,
        address: '0x4200000000000000000000000000000000000023',
        decimals: 18,
        symbol: 'WETH',
        name: 'Wrapped Ether',
        logoURI:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png'
      },
      []
    )
  ],
  [ChainId.BLAST_MAINNET]: [...WETH_ONLY[ChainId.BLAST_MAINNET]]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.BLAST_SEPOLIA]: [
    // [USDC, USDT],
    // [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    mobile: true
  },
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true
  // },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  }
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 500
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
