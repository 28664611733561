import React from 'react'
import TradingView from './TradingView'
import '../../style/components/TopBarTradingView.scss'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { FullScreenIcon, ZoomOutIcon } from '../../assets/icon'
import { Currency } from '@blast/v2-sdk'

const PartChart = ({ pair }: { pair: (Currency | undefined)[] }) => {
  const handle = useFullScreenHandle()

  const _renderTradingViewChart = () => {
    return (
      <div style={{ height: '100% ', width: '100%' }}>
        <TradingView
          containerId={'tv_chart_container'}
          fullscreen={false}
          handleFullscreen={!handle.active ? handle.enter : handle.exit}
          isFullScreen={handle.active}
          pair={pair}
        />
      </div>
    )
  }

  return (
    <div style={{ height: '520px' }} className="chart-tabs-container">
      <FullScreen handle={handle} className="fullscreen-container">
        {_renderTradingViewChart()}

        <div onClick={!handle.active ? handle.enter : handle.exit} className="icon-fullscreen">
          {handle.active ? <ZoomOutIcon cursor={'pointer'} /> : <FullScreenIcon cursor={'pointer'} />}
        </div>
      </FullScreen>
    </div>
  )
}
export default PartChart
