import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Popover, { PopoverProps } from '../Popover'

const TooltipContainer = styled.div<{ fontSize?: string }>`
  max-width: 200px;
  padding: 8px;
  font-size: ${({ fontSize }) => fontSize ?? '10px'};
  line-height: 14px;
  font-weight: 400;
  color: #e0e4e9;
`

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text: string
  fontSize?: string
}

export default function Tooltip({ text, fontSize, ...rest }: TooltipProps) {
  return <Popover content={<TooltipContainer fontSize={fontSize}>{text}</TooltipContainer>} {...rest} />
}

export function MouseoverTooltip({ children, ...rest }: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <Tooltip {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
    </Tooltip>
  )
}
