import BaseRequest from './BaseRequest'

export default class TradingRequest extends BaseRequest {
  getCandleChartData(
    token0Address: string,
    token1Address: string,
    params: { from: number; to: number; resolution: string }
  ) {
    const url = `/v1/candles/token0/${token0Address}/token1/${token1Address}`
    return this.get(url, params)
  }
}
