import axios from 'axios'

export default class BaseRequest {
  getUrlPrefix() {
    return process.env.REACT_APP_API_URL || 'https://dev-amm-candles.lagom.exchange/api'
  }

  async get(url: string, params?: any) {
    try {
      const config = {
        params,
        timeout: 5000 // TODO: change later
      }
      const response = await axios.get(this.getUrlPrefix() + url, config)
      return this._responseHandler(response)
    } catch (error) {
      return this._errorHandler(error)
    }
  }

  async _responseHandler(response: any) {
    return response.data
  }

  async _errorHandler(err: any) {
    if (err.response && err.response.data && err.response.data.data) {
      if (typeof err.response.data.data.message === 'string') {
        throw new Error(err.response.data.data.message)
      }
    }

    if (err.response && err.response.data && err.response.data.message) {
      if (typeof err.response.data.message === 'string') {
        throw new Error(err.response.data.message)
      }
      throw new Error(err.response.data.message[0])
    }
    if (err.response && err.response.data && err.response.data.error) {
      throw new Error(err.response.data.error)
    }
    throw err
  }
}
