import bgLabel from '../../assets/images/bg-label.png'
import IconStar from '../../assets/images/icon-star.png'
import React from 'react'
import styled from 'styled-components'

export const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

export const LabelBox = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  padding: 10px;
  margin-top: 20px;
  gap: 10px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 14px;
    line-height: 20px;
  `};
`

const Label = () => {
  return (
    <LabelBox>
      <Bg>
        <img src={bgLabel} />
      </Bg>
      <img width={'32px'} height={'32px'} src={IconStar} />
      <div>Get rewarded from your trading volume with Blast's yield</div>
    </LabelBox>
  )
}

export default Label
