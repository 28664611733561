import styled from 'styled-components'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { setUncaughtExceptionCaptureCallback } from 'process'
import { FixedSizeList } from 'react-window'

export const ModalInfo = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: 1rem 1rem;
  margin: 0.25rem 0.5rem;
  justify-content: center;
  flex: 1;
  user-select: none;
`

export const FadedSpan = styled(RowFixed)`
  color: ${({ theme }) => theme.primary1};
  font-size: 14px;
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px 32px;
`

export const PopularText = styled.div`
  padding: 0px 32px;
  color: #a3adbc;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
`

export const CustomFixedSizeList = styled(FixedSizeList)`
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      var(--static-background-high, #292f39);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(0deg, var(--alpha-60, rgba(0, 0, 0, 0.6)) 0%, var(--alpha-60, rgba(0, 0, 0, 0.6)) 100%),
      var(--static-foreground-subtle, #a3adbc);
    border-radius: 99px;
  }
`

export const MenuItem = styled(RowBetween)`
  margin-bottom: 8px;
  padding: 8px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
  grid-gap: 16px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: #373f49;
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
  :last-child {
    margin-bottom: 0px;
  }
`

export const SearchTokenHeader = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
`

export const SearchInputContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  padding: 10px 12px;

  border-radius: 6px;
  border: 1px solid #525f72;

  transition: border 100ms;
  :focus-within {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
`

export const SearchInputIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const SearchInput = styled.input`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;

  color: ${({ theme }) => theme.text1};
  -webkit-appearance: none;

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  ::placeholder {
    color: ${({ theme }) => theme.text3};
  }
`
export const Separator = styled.div`
  height: 1px;
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
  background-color: #525f72;
`

export const SeparatorDark = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

export const CommonToken = styled.div`
  border-radius: 100px;
`
