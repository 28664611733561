/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Currency } from '@blast/v2-sdk'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  HistoryCallback,
  IBasicDataFeed,
  LibrarySymbolInfo,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
  ThemeName,
  TradingTerminalWidgetOptions,
  widget
} from '../../charting_library/charting_library.min'
import { PairState, usePair } from '../../data/Reserves'
import { useAllTokens } from '../../hooks/Tokens'
import TradingRequest from '../../services/TradingRequest'
import { AppBroadcast } from '../../utils/broadcast'
import {
  DEFAULT_TRADING_VIEW_INTERVAL,
  SYMBOL_TYPE,
  THEME_MODE,
  addTradeToLastCandle,
  createEmptyCandleIfNeeded,
  getClientTimezone,
  getResolutionInMinutes,
  getResolutionString
} from '../../utils/chart'
import { roundNumberWithBase } from '../../utils/format'
import { ICandle, ITrade } from '../../utils/types'
import useSocket, { ESocketEvent } from '../provider/SocketProvider'
import NavbarTradingView from './NavbarTradingView'
import _ from 'lodash'

interface obj {
  [key: string]: boolean | number | string
}

interface Props {
  containerId: string
  libraryPath?: string
  chartsStorageUrl?: string
  chartsStorageApiVersion?: '1.0' | '1.1'
  clientId?: string
  userId?: string
  fullscreen?: boolean
  autosize?: boolean
  studiesOverrides?: obj
  className?: string
  height?: number
  handleFullscreen: any
  isFullScreen: boolean
  pair: (Currency | undefined)[]
}

const configurationData = {
  supports_search: true,
  supports_marks: true,
  intraday_multipliers: ['1S', '1', '3', '5', '15', '30', '60', '120', '240', '360', '480', '720'],
  supported_resolutions: [
    '1S',
    '1',
    '3',
    '5',
    '15',
    '30',
    '60',
    '120',
    '240',
    '360',
    '480',
    '720',
    '1D',
    '3D',
    '1W',
    '1M'
  ]
}

const getBackgroundColor = (theme: string): string => {
  return theme === THEME_MODE.LIGHT ? '#fafafc' : '#121318'
}

const TradingView: React.FC<Props> = props => {
  const listDisable = [
    'header_symbol_search',
    'header_undo_redo',
    'display_market_status',
    'timeframes_toolbar',
    'edit_buttons_in_legend',
    'volume_force_overlay',
    'legend_context_menu',
    'header_widget',
    'symbol_search_hot_key'
  ]

  const allTokens = useAllTokens()

  const [chartReady, setChartReady] = useState(false)
  const [pairState, pair] = usePair(props.pair[0] ?? undefined, props.pair[1] ?? undefined)
  const { publicEmit, publicListener } = useSocket()
  const interval = useMemo(() => getResolutionInMinutes(DEFAULT_TRADING_VIEW_INTERVAL), [])

  const theme = 'dark'
  const tradingViewTheme = (theme.charAt(0).toUpperCase() + theme.slice(1)) as ThemeName

  const currentPair = useMemo(() => {
    const addresses = _.sortBy([pair?.token1.address, pair?.token0.address]).join('_')
    if (pairState === PairState.EXISTS) {
      const needConvertToEth = props.pair[0]?.symbol === 'ETH' || props.pair[1]?.symbol === 'ETH'
      return {
        symbol: `${needConvertToEth && pair?.token1.symbol === 'WETH' ? 'ETH' : pair?.token1.symbol}/${
          needConvertToEth && pair?.token0.symbol === 'WETH' ? 'ETH' : pair?.token0.symbol
        }`,
        addresses: addresses,
        baseCurrencySymbol: props.pair[1]?.symbol,
        quoteCurrencySymbol: props.pair[0]?.symbol,
        pricePrecision: 8,
        quantityPrecision: 6
      }
    } else {
      return {}
    }
  }, [pairState, props?.pair[0]?.symbol, props?.pair[1]?.symbol])

  const ticker = useMemo(() => {
    if (pairState === PairState.EXISTS) {
      const needConvertToEth = props.pair[0]?.symbol === 'ETH' || props.pair[1]?.symbol === 'ETH'
      return {
        symbol: `${needConvertToEth && pair?.token1.symbol === 'WETH' ? 'ETH' : pair?.token1.symbol}/${
          needConvertToEth && pair?.token0.symbol === 'WETH' ? 'ETH' : pair?.token0.symbol
        }`
      }
    }

    if (pairState === PairState.LOADING) {
      return { symbol: null }
    }

    if (pairState === PairState.INVALID || pairState === PairState.NOT_EXISTS) {
      return { symbol: 'n/a' }
    }

    return { symbol: 'n/a' }
  }, [pairState, props?.pair[0]?.symbol, props?.pair[1]?.symbol])

  const intervalInMillisecondsRef = useRef<number>(getResolutionInMinutes(DEFAULT_TRADING_VIEW_INTERVAL) * 60 * 1000)
  const lastCandleRef = useRef<ICandle>({} as ICandle)
  const chartRealtimeCallback = useRef<(candle: ICandle) => void>(() => {})

  const onReady = (callback: any) => {
    setTimeout(() => callback(configurationData))
  }

  const getCurrencyAddress = useMemo(() => {
    return (currency: any) => {
      if (currency.symbol === 'ETH') {
        for (const [key, value] of Object.entries(allTokens)) {
          if (value.symbol === 'WETH') {
            return key
          }
        }
      }
      return currency?.address
    }
  }, [allTokens])

  const getBars = async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    from: number,
    to: number,
    onResult: HistoryCallback
  ) => {
    const intervalInSeconds = getResolutionInMinutes(resolution) * 60
    const startTime = roundNumberWithBase(from, intervalInSeconds) * 1000
    const endTime = roundNumberWithBase(to, intervalInSeconds) * 1000

    try {
      const params = {
        from: startTime,
        to: endTime,
        resolution: resolution
      }

      const dataResponse = await new TradingRequest().getCandleChartData(
        getCurrencyAddress(props.pair[0]) || '',
        getCurrencyAddress(props.pair[1]) || '',
        params
      )

      const data = dataResponse?.data
      if (data.length === 0) {
        onResult([], {
          noData: true
        })
        return
      } else {
        if (data[data.length - 1].time > Date.now()) {
          data.pop()
        }
      }

      const bars: any = data.map((bar: any) => ({
        time: bar.time,
        close: bar.close,
        open: bar.open,
        high: bar.high,
        low: bar.low,
        volume: bar.volume
      }))

      lastCandleRef.current = bars[bars.length - 1]
      onResult(bars, { noData: false })
    } catch (error) {
      console.log('Error in get bars')
      onResult([], { noData: true })
    }
  }

  const resolveSymbol = async (symbolName: string, onSymbolResolvedCallback: ResolveCallback) => {
    const symbol = ticker?.symbol || 'ETH/USDB'
    const symbolInfo: LibrarySymbolInfo = {
      ticker: symbol,
      name: symbol,
      description: symbol,
      pricescale: 10 ** Number(currentPair?.pricePrecision),
      volume_precision: currentPair?.quantityPrecision,
      minmov: 1,
      exchange: '',
      full_name: '',
      listed_exchange: '',
      session: '24x7',
      has_intraday: true,
      has_daily: true,
      has_seconds: true,
      seconds_multipliers: ['1'],
      has_weekly_and_monthly: false,
      intraday_multipliers: configurationData.intraday_multipliers,
      timezone: getClientTimezone(),
      type: SYMBOL_TYPE.bitcoin,
      supported_resolutions: configurationData.supported_resolutions
    }
    onSymbolResolvedCallback(symbolInfo)
  }

  useEffect(() => {
    if (!pair) return
    if (currentPair?.addresses) {
      console.log(`SUBSCRIBE pair: ${currentPair?.addresses?.toLocaleLowerCase()}`)
      publicEmit(ESocketEvent.SUBSCRIBE, {
        channel: 'trades',
        symbol: currentPair?.addresses?.toLocaleLowerCase()
      })

      publicListener(ESocketEvent.TRADE_CREATED, function(data) {
        AppBroadcast.dispatch('TradesCreated', data)
      })
    }

    return () => {
      console.log(`UN_SUBSCRIBE pair: ${currentPair?.addresses?.toLocaleLowerCase()}`)
      publicEmit(ESocketEvent.UN_SUBSCRIBE, {
        channel: 'trades',
        symbol: currentPair?.addresses?.toLocaleLowerCase()
      })
    }
  }, [currentPair, publicEmit, publicListener])

  const subscribeBars = (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback
  ) => {
    chartRealtimeCallback.current = onRealtimeCallback
    AppBroadcast.on('TradesCreated', (trade: ITrade) => {
      console.log('AppBroadcast: ')
      const intervalInMilliseconds = intervalInMillisecondsRef.current
      lastCandleRef.current = addTradeToLastCandle(
        trade,
        lastCandleRef.current,
        intervalInMilliseconds,
        onRealtimeCallback
      )
    })
    AppBroadcast.remove('TradesCreated')
  }

  const tradingViewChart = useRef<any>({})

  const selectInterval = (value: any) => {
    // setInterval(value);
    tradingViewChart.current.chart().setResolution(value, null)
  }

  const setChartType = (chartTypeNumber: number) => {
    tradingViewChart.current.chart().setChartType(chartTypeNumber)
  }

  const datafeed: IBasicDataFeed = {
    onReady,
    searchSymbols: () => {},
    resolveSymbol,
    getBars,
    subscribeBars,
    unsubscribeBars: () => {}
  }

  useEffect(() => {
    const interval = window.setInterval(() => {
      const lastCandle = lastCandleRef.current

      const intervalInMilliseconds = intervalInMillisecondsRef.current
      lastCandleRef.current = createEmptyCandleIfNeeded(
        lastCandle,
        intervalInMilliseconds,
        chartRealtimeCallback.current
      )
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const widgetOptions: TradingTerminalWidgetOptions = {
      locale: 'en',
      theme: tradingViewTheme,
      autosize: true,
      fullscreen: false,
      datafeed: datafeed,
      disabled_features: listDisable,
      interval: DEFAULT_TRADING_VIEW_INTERVAL,
      enabled_features: ['seconds_resolution'],
      overrides: {
        volumePaneSize: 'medium',
        'paneProperties.background': getBackgroundColor(theme),
        'mainSeriesProperties.candleStyle.upColor': '#16C782',
        'mainSeriesProperties.candleStyle.downColor': '#EA3943',
        'mainSeriesProperties.candleStyle.drawWick': true,
        'mainSeriesProperties.candleStyle.drawBorder': true,
        'mainSeriesProperties.candleStyle.borderColor': '#378658',
        'mainSeriesProperties.candleStyle.borderUpColor': '#16C782',
        'mainSeriesProperties.candleStyle.borderDownColor': '#EA3943',
        'mainSeriesProperties.candleStyle.wickUpColor': '#16C782',
        'mainSeriesProperties.candleStyle.wickDownColor': '#EA3943'
      },
      symbol: ticker?.symbol || '',
      timezone: getClientTimezone(),
      library_path: props.libraryPath,
      charts_storage_api_version: props.chartsStorageApiVersion,
      charts_storage_url: props.chartsStorageUrl,
      user_id: props.userId,
      client_id: props.clientId,
      studies_overrides: {
        'volume.show ma': true
      },
      container_id: props.containerId
    }

    const chart = new widget(widgetOptions)
    tradingViewChart.current = chart
    chart.onChartReady(() => {
      setChartReady(true)
      chart.chart().setResolution(getResolutionString(interval), () => {})
      chart.applyOverrides({ 'paneProperties.topMargin': 15 })
      chart
        .chart()
        .onIntervalChanged()
        .subscribe(null, function(interval: any) {
          intervalInMillisecondsRef.current = getResolutionInMinutes(interval) * 60 * 1000
        })
    })
  }, [ticker?.symbol])

  const openIndicatorPopup = () => {
    tradingViewChart.current.chart().executeActionById('insertIndicator')
  }

  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexFlow: 'column' }}>
      {chartReady ? (
        <NavbarTradingView
          isFullScreen={props.isFullScreen}
          onSelectInterval={selectInterval}
          openIndicatorPopup={openIndicatorPopup}
          setChartType={setChartType}
          handleFullscreen={props.handleFullscreen}
          symbol={ticker?.symbol}
        />
      ) : (
        <div style={{ paddingTop: '48px' }}></div>
      )}

      <div
        style={{
          ...(props.isFullScreen ? { height: '95vh' } : { flexGrow: 1 }),
          borderRadius: '4px',
          overflow: 'hidden'
        }}
        id={props.containerId}
      />
    </div>
  )
}

TradingView.defaultProps = {
  containerId: 'tv_chart_container',
  libraryPath: '/charting_library/',
  chartsStorageApiVersion: '1.1',
  clientId: 'tradingview.com',
  userId: 'public_user_id',
  fullscreen: true,
  height: 500,
  autosize: true,
  studiesOverrides: {
    'volume.volume.color.0': 'rgba(247, 73, 64, 0.19)',
    'volume.volume.color.1': 'rgba(41, 155, 130, 0.2)',
    'volume.volume.transparency': 15,
    'volume.volume ma.color': '#f74940',
    'volume.volume ma.transparency': 0,
    'volume.volume ma.linewidth': 1,
    'volume.volume ma.plottype': 'line',
    'volume.show ma': true
  }
}
export default TradingView
