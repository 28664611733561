import { Trade, TradeType } from '@blast/v2-sdk'
import React, { useContext, useMemo, useState } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { ChevronDownIcon, TYPE } from '../../theme'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity
} from '../../utils/prices'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { StyledBalanceMaxMini, SwapCallbackError } from './styleds'

const LineTextCenter = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #3e4755;
  line-height: 0.1em;
  margin: 0px 0px 24px 0px;
  font-family: Inter;
  color: #8391a6;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
`

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm
}: {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const [showMore, setShowMore] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade
  ])
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)

  return (
    <>
      <AutoColumn gap="0px">
        <RowBetween align="center">
          <LineTextCenter onClick={() => setShowMore(prevValue => !prevValue)}>
            <div
              style={{
                background: '#292F39',
                padding: '0px 4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {showMore ? 'Show less' : 'Show more'}
              <ChevronDownIcon style={{ marginLeft: '5px', height: '20px' }} />
            </div>
          </LineTextCenter>
        </RowBetween>

        <RowBetween align="center" style={{ fontSize: '12px' }}>
          <Text fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#A3ADBC'}>
            Price
          </Text>
          <Text
            fontWeight={400}
            fontSize={'12px'}
            lineHeight={'16px'}
            color={'#A3ADBC'}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              textAlign: 'right',
              paddingLeft: '10px'
            }}
          >
            {formatExecutionPrice(trade, showInverted)}
            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <Repeat size={14} />
            </StyledBalanceMaxMini>
          </Text>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#A3ADBC'}>
              {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
            </TYPE.black>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixed>
          <RowFixed>
            <TYPE.black fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#A3ADBC'}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
            </TYPE.black>
            <TYPE.black fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#A3ADBC'} marginLeft={'4px'}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#A3ADBC'}>
              Price Impact
            </TYPE.black>
            <QuestionHelper text="The difference between the market price and your price due to trade size." />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#A3ADBC'}>
              Liquidity Provider Fee
            </TYPE.black>
            <QuestionHelper text="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive." />
          </RowFixed>
          <TYPE.black fontWeight={400} fontSize={'12px'} lineHeight={'16px'} color={'#A3ADBC'}>
            {realizedLPFee ? realizedLPFee?.toSignificant(6) + ' ' + trade.inputAmount.currency.symbol : '-'}
          </TYPE.black>
        </RowBetween>
      </AutoColumn>

      <AutoRow justify="center">
        <ButtonError
          onClick={onConfirm}
          disabled={disabledConfirm}
          error={severity > 2}
          style={{ padding: '8px 20px', width: '212px' }}
          id="confirm-swap-or-send"
        >
          <Text fontSize={'16px'} fontWeight={400} lineHeight={'24px'}>
            {severity > 2 ? 'Swap Anyway' : 'Approve and swap'}
          </Text>
        </ButtonError>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
